import { Table } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import css from './index.module.scss';
import { useGlobal } from '@/utils/useGlobal';
import { useRouter } from 'next/router';

export default function DirectConnection() {
    const { useLang } = useGlobal();
    const { t } = useLang('app');
    const { locale } = useRouter();

    interface DataType {
        productName: string;
        iot: boolean;
        bluetooth: boolean;
        direct: boolean;
    }

    const data: DataType[] = [
        { productName: t('quickGuideChild.pro16'), iot: true, bluetooth: true, direct: false },
        { productName: t('quickGuideChild.pro17'), iot: true, bluetooth: true, direct: false },
        { productName: t('quickGuideChild.pro18'), iot: true, bluetooth: true, direct: false },
        { productName: t('quickGuideChild.pro1'), iot: true, bluetooth: false, direct: true },
        { productName: t('quickGuideChild.pro2'), iot: true, bluetooth: false, direct: true },
        { productName: t('quickGuideChild.pro3'), iot: true, bluetooth: false, direct: true },
        { productName: t('quickGuideChild.pro4'), iot: true, bluetooth: false, direct: true },
        { productName: t('quickGuideChild.pro5'), iot: true, bluetooth: false, direct: true },
        { productName: t('quickGuideChild.pro6'), iot: false, bluetooth: false, direct: false },
        { productName: t('quickGuideChild.pro7'), iot: true, bluetooth: true, direct: false },
        { productName: t('quickGuideChild.pro8'), iot: true, bluetooth: false, direct: true },
        { productName: t('quickGuideChild.pro9'), iot: true, bluetooth: true, direct: true },
        { productName: t('quickGuideChild.pro10'), iot: true, bluetooth: true, direct: false },
        { productName: t('quickGuideChild.pro11'), iot: true, bluetooth: true, direct: false },
        { productName: t('quickGuideChild.pro12'), iot: false, bluetooth: true, direct: false },
        { productName: t('quickGuideChild.pro13'), iot: true, bluetooth: true, direct: false },
        { productName: t('quickGuideChild.pro14'), iot: true, bluetooth: false, direct: true },
        { productName: t('quickGuideChild.pro15'), iot: true, bluetooth: true, direct: false },
    ];
    const jpData: DataType[] = [
        { productName: t('quickGuideChild.pro16'), iot: true, bluetooth: true, direct: false },
        { productName: t('quickGuideChild.pro17'), iot: true, bluetooth: true, direct: false },
        { productName: t('quickGuideChild.pro18'), iot: true, bluetooth: true, direct: false },
        { productName: t('quickGuideChild.pro1'), iot: true, bluetooth: false, direct: true },
        { productName: t('quickGuideChild.pro2'), iot: true, bluetooth: false, direct: true },
        { productName: t('quickGuideChild.pro3'), iot: true, bluetooth: false, direct: true },
        { productName: t('quickGuideChild.pro4'), iot: true, bluetooth: false, direct: true },
        { productName: t('quickGuideChild.pro5'), iot: true, bluetooth: false, direct: true },
        { productName: t('quickGuideChild.pro19'), iot: true, bluetooth: true, direct: false },
        { productName: t('quickGuideChild.pro20'), iot: true, bluetooth: true, direct: false },
        { productName: t('quickGuideChild.pro6'), iot: false, bluetooth: false, direct: false },
        { productName: t('quickGuideChild.pro7'), iot: true, bluetooth: true, direct: false },
        { productName: t('quickGuideChild.pro21'), iot: true, bluetooth: true, direct: false },
        { productName: t('quickGuideChild.pro22'), iot: true, bluetooth: true, direct: false },
        { productName: t('quickGuideChild.pro23'), iot: true, bluetooth: true, direct: false },
        { productName: t('quickGuideChild.pro8'), iot: true, bluetooth: false, direct: true },
        { productName: t('quickGuideChild.pro9'), iot: true, bluetooth: true, direct: true },
        { productName: t('quickGuideChild.pro24'), iot: true, bluetooth: true, direct: false },
        { productName: t('quickGuideChild.pro25'), iot: true, bluetooth: true, direct: false },
        { productName: t('quickGuideChild.pro10'), iot: true, bluetooth: true, direct: false },
        { productName: t('quickGuideChild.pro11'), iot: true, bluetooth: true, direct: false },
        { productName: t('quickGuideChild.pro26'), iot: true, bluetooth: true, direct: false },
        { productName: t('quickGuideChild.pro27'), iot: true, bluetooth: true, direct: false },
        { productName: t('quickGuideChild.pro12'), iot: false, bluetooth: true, direct: false },
        { productName: t('quickGuideChild.pro13'), iot: true, bluetooth: true, direct: false },
        { productName: t('quickGuideChild.pro14'), iot: true, bluetooth: false, direct: true },
        { productName: t('quickGuideChild.pro15'), iot: true, bluetooth: true, direct: false },
        { productName: t('quickGuideChild.pro28'), iot: false, bluetooth: false, direct: false },
        { productName: t('quickGuideChild.pro29'), iot: false, bluetooth: true, direct: false },
    ];
    const columns: ColumnsType<DataType> = [
        {
            title: t('quickGuideChild.productName'),
            dataIndex: 'productName',
            key: 'productName',
            width: 180,
        },
        {
            title: t('quickGuideChild.tab1'),
            dataIndex: 'iot',
            align: 'center',
            width: 180,
            render: (_, record) => (record.iot ? <span className="circle"></span> : '-'),
        },
        {
            title: t('quickGuideChild.tab2'),
            dataIndex: 'bluetooth',
            align: 'center',
            width: 180,
            render: (_, record) => (record.bluetooth ? <span className="circle"></span> : '-'),
        },
        {
            title: t('quickGuideChild.tab3'),
            dataIndex: 'direct',
            align: 'center',
            width: 180,
            render: (_, record) => (record.direct ? <span className="circle"></span> : '-'),
        },
    ];

    return (
        <div className={css.directConnection}>
            <div className="table-box">
                <div className="table-title">{t('quickGuideChild.content-title')}</div>
                <Table scroll={{ x: '100%' }} columns={columns} dataSource={locale === 'jp' ? jpData : data} bordered pagination={false} />
            </div>
        </div>
    );
}
