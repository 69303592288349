export default function (props: any) {
    return (
        <svg viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g fill="none" fillRule="evenodd">
                <circle fillOpacity=".9" fill="#999" cx="22" cy="22" r="17" />
                <path d="M21.914 17.171a5.914 5.914 0 1 0 4.605 2.203" stroke="#1A1A1A" strokeWidth="2" strokeLinecap="round" />
                <path stroke="#1A1A1A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" d="M20.258 15l2.512 2.261-2.261 2.512" />
            </g>
        </svg>
    );
}

export const BackdropReplay = (props: any) => {
    const { circlefill = '#FFF', fill = '#FFF' } = props;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="1em" height="1em" viewBox="0 0 40 40" version="1.1" {...props}>
            <g id="终稿0323" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="wave2" transform="translate(-1460.000000, -3160.000000)" fill={fill}>
                    <g id="icon-replay" transform="translate(1460.000000, 3160.000000)">
                        <circle
                            id="circle"
                            strokeOpacity="0.5"
                            stroke={props?.stroke || '#FFFFFF'}
                            fill={circlefill || fill}
                            fillOpacity={props?.fillOpacity || '0.3'}
                            cx="20"
                            cy="20"
                            r="19.5"
                        />
                        <path
                            d="M18.6310979,10.8044942 L18.7383545,10.8904179 L21.69315,13.5509276 C22.1415162,13.9546384 22.207157,14.6248809 21.8661492,15.1051715 L21.7802255,15.2124281 L19.1197158,18.1672236 C18.6849503,18.6500795 17.9410712,18.6890646 17.4582152,18.2542991 C17.009849,17.8505884 16.9442082,17.1803459 17.2852161,16.7000552 L17.3711397,16.5927986 L18.1009364,15.7806771 C15.7883039,16.5368521 14.1176471,18.7119109 14.1176471,21.2771725 C14.1176471,24.4702902 16.7061804,27.0588235 19.899298,27.0588235 C23.0924157,27.0588235 25.680949,24.4702902 25.680949,21.2771725 C25.680949,19.9369424 25.2249903,18.6696823 24.4011436,17.6489999 C23.9930487,17.1434018 24.0720906,16.4027073 24.5776887,15.9946124 C25.0832867,15.5865175 25.8239812,15.6655594 26.2320762,16.1711575 C27.3907108,17.6066162 28.0338902,19.3942246 28.0338902,21.2771725 C28.0338902,25.7697837 24.3919092,29.4117647 19.899298,29.4117647 C15.4066868,29.4117647 11.7647059,25.7697837 11.7647059,21.2771725 C11.7647059,17.446578 14.4124279,14.234392 17.9775915,13.370895 L17.1639296,12.6389939 C16.7155633,12.2352831 16.6499225,11.5650407 16.9909304,11.08475 L17.076854,10.9774934 C17.4805648,10.5291272 18.1508072,10.4634863 18.6310979,10.8044942 Z"
                            id="形状结合"
                            fillOpacity="0.9"
                            fillRule="nonzero"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};
