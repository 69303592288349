import { useGlobal } from '@/utils/useGlobal';
import css from './index.module.scss';
import { TimelineMax, TweenMax } from 'gsap';

export default function SecondSeriesInApp() {
    const { useLang, Image, useEffect, useState, useRouter } = useGlobal();
    const { t } = useLang('app');
    const { locale } = useRouter();
    let controller;
    const [img1, setImg1] = useState('/img/appDownload/pc/04-1.png');
    const [img2, setImg2] = useState('/img/appDownload/pc/04-3.png');
    const [img3, setImg3] = useState('/img/appDownload/pc/04-2.png');

    useEffect(() => {
        controller = new ScrollMagic.Controller();
    }, []);

    // 固定
    useEffect(() => {
        const scene = new ScrollMagic.Scene({
            triggerElement: '.secondSeriesInApp', //触发元素
            offset: 0, //从开始点滚动多少px触发,可为负数
            triggerHook: 0.9, //触发元素开始进入视口时触发:1 进入,0 离开, 0.5 中间,
            duration: '90%', //和滚动条绑定到一起,场景持续滚动距离为 800px
        });
        const timeline = new TimelineMax();
        const tween = TweenMax.to('.title', 0.5, { opacity: 1 });
        const tween0 = TweenMax.to('.ani-box', 0.5, { marginTop: 90 });
        const tween1 = TweenMax.to('.secondSeriesInApp .sm-img', 0.6, {
            width: 792,
            height: 660,
            ease: 'none',
        });

        const tween2 = TweenMax.to('.secondSeriesInApp .left-phone', 0.2, { opacity: 1 });
        const tween3 = TweenMax.to('.secondSeriesInApp .left-phone', 1, { top: -450 });
        const tween4 = TweenMax.to('.secondSeriesInApp .right-phone', 0.3, { opacity: 1 });
        const tween5 = TweenMax.to('.secondSeriesInApp .right-phone', 0.8, { top: -450 });
        const tween6 = TweenMax.to('.secondSeriesInApp .bottom-phone', 0.3, { opacity: 1 });
        const tween7 = TweenMax.to('.secondSeriesInApp .bottom-phone', 0.8, { top: 0 });
        timeline.add(tween).add(tween0).add(tween1, 2.4).add(tween2, 2.6).add(tween3, 2.6).add(tween4, 3).add(tween5, 3).add(tween6, 3.2).add(tween7, 3.2);
        scene.setTween(timeline);
        controller.addScene(scene);
    }, []);

    useEffect(() => {
        if (['us', 'au', 'eu', 'ca', 'uk', 'za', 'cis', 'br'].includes(locale)) {
            setImg1('/img/appDownload/pc/04-1.png');
            setImg2('/img/appDownload/pc/04-3.png');
            setImg3('/img/appDownload/pc/04-2.png');
        } else {
            setImg1(`/img/appDownload/pc/04-1-${locale}.png`);
            setImg2(`/img/appDownload/pc/04-3-${locale}.png`);
            setImg3(`/img/appDownload/pc/04-2-${locale}.png`);
        }
    }, []);

    return (
        <div className={`${css.secondSeriesInApp} secondSeriesInApp`}>
            <div className="second-series">
                <div className="title">{t('secondSeriesInApp.title')}</div>
                <div className="ani-box">
                    <div className="sm-img">
                        <Image src="/img/appDownload/pc/shp.png" className="fullImgs" layout="fill" />
                    </div>
                </div>
                <div className="move-box">
                    <div className="top-box">
                        <div className="phone-box left-phone">
                            <Image src={img1} layout="fill" className="fullImgs" />
                            <div className="phone-text">{t('secondSeriesInApp.phone-txt1')}</div>
                            <div className="phone-desc">{t('secondSeriesInApp.phone-desc1')}</div>
                        </div>
                        <div className="phone-box right-phone">
                            <Image src={img2} layout="fill" className="fullImgs" />
                            <div className="phone-text">{t('secondSeriesInApp.phone-txt2')}</div>
                            <div className="phone-desc">{t('secondSeriesInApp.phone-desc3')}</div>
                        </div>
                    </div>
                    <div className="bottom-box">
                        <div className="phone-box bottom-phone">
                            <Image src={img3} layout="fill" className="fullImgs" />
                            <div className="phone-text">{t('secondSeriesInApp.phone-txt3')}</div>
                            <div className="phone-desc">{t('secondSeriesInApp.phone-desc2')}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
