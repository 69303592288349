import css from './index.module.scss';
import { useGlobal } from '@/utils/useGlobal';
import {
    EFTabIotNotSelectPc,
    EFTabIotSelectPc,
    EFTabApNotSelectPc,
    EFTabApSelectPc,
    EFTabBluetoothNotSelectPc,
    EFTabBluetoothSelectPc,
} from '@/components/UI/svg/appDownload';
import EFVideo from '@/components/UI/EFVideo';

export default function IotComp() {
    const { useLang, useState, useEffect } = useGlobal();
    const { t } = useLang('app');
    const [activeTab, setActiveTab] = useState(0);
    const [selectTab, setSelectTab] = useState({});

    const data = [
        {
            title: t('quickGuideChild.tab1'),
            desc: t('quickGuideChild.iot-desc'),
            video: ['/video/app/pc/d4d2fd3f79515806ab30a9210f34f39a.mp4', '/video/app/pc/67cec8c8dd1ed91dcd9680213c759dd1.webm'],
            svg: <EFTabIotNotSelectPc width="28px" height="28px" />,
            selectSvg: <EFTabIotSelectPc width="28px" height="28px" />,
        },
        {
            title: t('quickGuideChild.tab3'),
            desc: t('quickGuideChild.direct-desc'),
            video: ['/video/app/pc/2b3fd47b7582c9bd1d47ed005884ef67.mp4', '/video/app/pc/7bb38526d4b890085b6dbd11cc734ac0.webm'],
            svg: <EFTabApNotSelectPc width="28px" height="28px" />,
            selectSvg: <EFTabApSelectPc width="28px" height="28px" />,
        },
        {
            title: t('quickGuideChild.tab2'),
            desc: t('quickGuideChild.bluetooth-desc'),
            video: ['/video/app/pc/20ed44ea2fec50082177fc5ea6fe3c17.mp4', '/video/app/pc/c9dad063ea5a5ff6960af67939eb6277.webm'],
            svg: <EFTabBluetoothNotSelectPc width="28px" height="28px" />,
            selectSvg: <EFTabBluetoothSelectPc width="28px" height="28px" />,
        },
    ];

    const clickTab = (index: number) => {
        setActiveTab(index);
        setSelectTab(data[index]);
    };

    useEffect(() => {
        setSelectTab(data[activeTab]);
    }, []);

    return (
        <div className={css.iotComp}>
            <div className="iot-comp">
                <div className="tab-box">
                    {data.map((item: any, index) => (
                        <div className={`item-box ${activeTab === index ? 'active' : ''}`} key={index} onClick={() => clickTab(index)}>
                            {activeTab === index ? item.selectSvg : item.svg}
                            <span className="tab-item">{item.title}</span>
                        </div>
                    ))}
                </div>
                <div className="box">
                    <div className="desc">{selectTab.desc}</div>
                    <div className="video-box">
                        <EFVideo key={selectTab.title} sources={selectTab.video} className="video" />
                    </div>
                </div>
            </div>
        </div>
    );
}
