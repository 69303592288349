import Upgrade, { PartTips } from '@elem/compPage/App';
import FirstScreen from './FirstScreen';
import SecondScreen from './SecondScreen';
import FirstSeriesInApp from './FirstSeriesInApp';
import SecondSeriesInApp from './SecondSeriesInApp';
import DownloadSection from './DownloadSection';
// import NewComp1 from './NewComp1';
// import NewComp2 from './NewComp2';
// import NewComp3 from './NewComp3';
import { useGlobal } from '@/utils/useGlobal';

const UPGRADE_SITE = ['us', 'uk', 'eu', 'au', 'ca', 'de', 'jp', 'fr', 'br'];

export default function OverView({ clickHere, iosAppVersionInfo, fileUrl }: any) {
    const { useRouter } = useGlobal();
    const { locale } = useRouter();

    return (
        <div>
            <div className="section1">
                <FirstScreen fileUrl={fileUrl} UPGRADE_SITE={UPGRADE_SITE} />
            </div>
            <div className="section2">
                <SecondScreen />
            </div>
            {!UPGRADE_SITE.includes(locale) && (
                <>
                    <div className="section3">
                        <FirstSeriesInApp />
                    </div>
                    <div className="section4">
                        <SecondSeriesInApp />
                    </div>
                </>
            )}
            {UPGRADE_SITE.includes(locale) && <Upgrade />}
            <div className="section5" style={{ backgroundColor: '#f8f8f8;' }}>
                <DownloadSection clickHere={clickHere} iosAppVersionInfo={iosAppVersionInfo} />
                {UPGRADE_SITE.includes(locale) && <PartTips />}
            </div>
        </div>
    );
}
