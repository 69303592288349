import { TimelineMax, TweenMax } from 'gsap';
import { useState, useEffect } from 'react';
import styled from './index.module.scss';
import { useGlobal } from '@/utils/useGlobal';
import { EFConnectionIcon, EFIOTModeIcon, EFBluetoothIcon } from '@/components/UI/svg/appDownload';

export default function FirstSeriesInApp() {
    const { Image, useLang, useRouter } = useGlobal();
    const { t } = useLang('app');
    let controller: any;
    const [isEnterA1, setIsEnterA1] = useState(true);
    const [isEnterA2, setIsEnterA2] = useState(false);
    const [isEnterA3, setIsEnterA3] = useState(false);
    const { locale } = useRouter();
    const [img1, setImg1] = useState('/img/appDownload/pc/03_01_phone.png');
    const [img2, setImg2] = useState('/img/appDownload/pc/03_02_phone.png');
    const [img3, setImg3] = useState('/img/appDownload/pc/03_03_phone.png');
    const [dynamoImg, setDynamoImg] = useState([]);
    const imgMap = {
        us: ['/img/appDownload/pc/03_02_dynamo.png', '/img/appDownload/pc/03_03_dynamo.png'],
        uk: ['/img/appDownload/pc/03_02_dynamo_uk.png', '/img/appDownload/pc/03_03_dynamo_uk.png'],
        au: ['/img/appDownload/pc/03_02_dynamo_au.png', '/img/appDownload/pc/03_03_dynamo_au.png'],
        ca: ['/img/appDownload/pc/03_02_dynamo.png', '/img/appDownload/pc/03_03_dynamo.png'],
        de: ['/img/appDownload/pc/03_02_dynamo_fr.png', '/img/appDownload/pc/03_03_dynamo_fr.png'],
        fr: ['/img/appDownload/pc/03_02_dynamo_fr.png', '/img/appDownload/pc/03_03_dynamo_fr.png'],
        eu: ['/img/appDownload/pc/03_02_dynamo_fr.png', '/img/appDownload/pc/03_03_dynamo_fr.png'],
        ru: ['/img/appDownload/pc/03_02_dynamo_fr.png', '/img/appDownload/pc/03_03_dynamo_fr.png'],
        cis: ['/img/appDownload/pc/03_02_dynamo_fr.png', '/img/appDownload/pc/03_03_dynamo_fr.png'],
        jp: ['/img/appDownload/pc/03_02_dynamo.png', '/img/appDownload/pc/03_03_dynamo_jp.png'],
        cn: ['/img/appDownload/pc/03_02_dynamo_cn.png', '/img/appDownload/pc/03_03_dynamo_cn.png'],
        za: ['/img/appDownload/pc/03_02_dynamo_za.png', '/img/appDownload/pc/03_03_dynamo_za.png'],
    };

    const scollList = [
        {
            title: t('firstSeriesInApp.title1'),
            desc: t('firstSeriesInApp.desc1'),
        },
        {
            title: t('firstSeriesInApp.title2'),
            desc: t('firstSeriesInApp.desc2'),
        },
        {
            title: t('firstSeriesInApp.title3'),
            desc: t('firstSeriesInApp.desc3'),
            iconList: [
                {
                    icon: <EFConnectionIcon />,
                    text: t('firstSeriesInApp.directConnection'),
                },
                {
                    icon: <EFIOTModeIcon />,
                    text: t('firstSeriesInApp.iotMode'),
                },
                {
                    icon: <EFBluetoothIcon />,
                    text: t('firstSeriesInApp.bluetooth'),
                },
            ],
        },
    ];

    useEffect(() => {
        controller = new ScrollMagic.Controller();
    }, []);

    // 第1屏--变化1
    useEffect(() => {
        const sceneA1 = new ScrollMagic.Scene({
            triggerElement: '.firstSeriesInApp .first_scoll .text', //触发元素
            offset: 50, //从开始点滚动多少px触发,可为负数
            triggerHook: 1, //触发元素开始进入视口时触发:1 进入,0 离开, 0.5 中间,
            duration: 450, //和滚动条绑定到一起,场景持续滚动距离为 800px
        });

        const timelineA1 = new TimelineMax();
        const tweenA1 = TweenMax.fromTo('.firstSeriesInApp .first_scoll .text', 0.05, { opacity: 0 }, { opacity: 1 });
        timelineA1.add(tweenA1);
        const tweenA2 = TweenMax.fromTo('.firstSeriesInApp .sticky01 .sticky_in', 0.3, { y: 300 }, { y: 0 });
        timelineA1.add(tweenA2);
        const tweenA3 = TweenMax.fromTo('.firstSeriesInApp .sticky01 .dynamo_in', 0.1, { y: 200 }, { y: 0 });
        timelineA1.add(tweenA3, 0.2);

        sceneA1.setTween(timelineA1);
        controller.addScene(sceneA1);
    }, []);

    // 第2屏
    useEffect(() => {
        const sceneA1 = new ScrollMagic.Scene({
            triggerElement: '.firstSeriesInApp .app_series_scoll_item_0', //触发元素
            offset: 50, //从开始点滚动多少px触发,可为负数
            triggerHook: 1, //触发元素开始进入视口时触发:1 进入,0 离开, 0.5 中间,
            duration: 150, //和滚动条绑定到一起,场景持续滚动距离为 800px
        });

        const tweenA1 = TweenMax.fromTo('.firstSeriesInApp .app_series_scoll_item_0 .title', 0.1, { opacity: 0 }, { opacity: 1 });

        sceneA1.setTween(tweenA1);
        controller.addScene(sceneA1);
    }, []);

    // 第2屏
    useEffect(() => {
        const sceneA1 = new ScrollMagic.Scene({
            triggerElement: '.firstSeriesInApp .app_series_scoll_item_1', //触发元素
            offset: 50, //从开始点滚动多少px触发,可为负数
            triggerHook: 1, //触发元素开始进入视口时触发:1 进入,0 离开, 0.5 中间,
            duration: 150, //和滚动条绑定到一起,场景持续滚动距离为 800px
        });

        const tweenA1 = TweenMax.fromTo('.firstSeriesInApp .app_series_scoll_item_1 .title', 0.1, { opacity: 0 }, { opacity: 1 });

        sceneA1.setTween(tweenA1);
        controller.addScene(sceneA1);
    }, []);

    // 第3屏
    useEffect(() => {
        const sceneA1 = new ScrollMagic.Scene({
            triggerElement: '.firstSeriesInApp .app_series_scoll_item_2', //触发元素
            offset: 50, //从开始点滚动多少px触发,可为负数
            triggerHook: 1, //触发元素开始进入视口时触发:1 进入,0 离开, 0.5 中间,
            duration: 150, //和滚动条绑定到一起,场景持续滚动距离为 800px
        });
        const tweenA1 = TweenMax.fromTo('.firstSeriesInApp .app_series_scoll_item_2 .title', 0.1, { opacity: 0 }, { opacity: 1 });
        sceneA1.setTween(tweenA1);
        controller.addScene(sceneA1);
    }, []);

    // 第1屏
    useEffect(() => {
        const sceneA1 = new ScrollMagic.Scene({
            triggerElement: '.firstSeriesInApp .app_series_scoll_item_0', //触发元素
            offset: 0, //从开始点滚动多少px触发,可为负数
            triggerHook: 1, //触发元素开始进入视口时触发:1 进入,0 离开, 0.5 中间,
            duration: '90%', //和滚动条绑定到一起,场景持续滚动距离为 800px
        });
        sceneA1.on('enter', function () {
            setIsEnterA1(true);
            setIsEnterA2(false);
            setIsEnterA3(false);
        });

        controller.addScene(sceneA1);
    }, []);

    // 第2屏
    useEffect(() => {
        const sceneA1 = new ScrollMagic.Scene({
            triggerElement: '.firstSeriesInApp .app_series_scoll_item_1', //触发元素
            offset: 300, //从开始点滚动多少px触发,可为负数
            triggerHook: 1, //触发元素开始进入视口时触发:1 进入,0 离开, 0.5 中间,
            duration: '100%', //和滚动条绑定到一起,场景持续滚动距离为 800px
        });
        sceneA1.on('enter', function () {
            setIsEnterA1(false);
            setIsEnterA2(true);
            setIsEnterA3(false);
        });

        controller.addScene(sceneA1);
    }, []);

    // 第3屏
    useEffect(() => {
        const sceneA1 = new ScrollMagic.Scene({
            triggerElement: '.firstSeriesInApp .app_series_scoll_item_2', //触发元素
            offset: 350, //从开始点滚动多少px触发,可为负数
            triggerHook: 1, //触发元素开始进入视口时触发:1 进入,0 离开, 0.5 中间,
            duration: '300%', //和滚动条绑定到一起,场景持续滚动距离为 800px
        });
        sceneA1.on('enter', function () {
            setIsEnterA1(false);
            setIsEnterA2(false);
            setIsEnterA3(true);
        });

        controller.addScene(sceneA1);
    }, []);

    useEffect(() => {
        if (['us', 'au', 'eu', 'ca', 'uk', 'za', 'cis', 'br'].includes(locale)) {
            setImg1('/img/appDownload/pc/03_01_phone.png');
            setImg2('/img/appDownload/pc/03_02_phone.png');
            setImg3('/img/appDownload/pc/03_03_phone.png');
        } else {
            setImg1(`/img/appDownload/pc/03_01_phone_${locale}.png`);
            setImg2(`/img/appDownload/pc/03_02_phone_${locale}.png`);
            setImg3(`/img/appDownload/pc/03_03_phone_${locale}.png`);
        }
        setDynamoImg(imgMap[locale as keyof typeof imgMap] || imgMap.us);
    }, []);

    return (
        <div className={`${styled.firstSeriesInApp} firstSeriesInApp`}>
            <div className="series_scoll_top"></div>
            <div className="container clearfix">
                <div className="app_series_scoll">
                    <div className="app_series_scoll_item first_scoll">
                        <div className="text">{t('firstSeriesInApp.topTitle')}</div>
                    </div>
                    {scollList.map((item, index) => (
                        <div className={`app_series_scoll_item app_series_scoll_item_${index}`} key={index}>
                            <h6 className={`title scoll0${index + 1}`}>{item.title}</h6>
                            <p className="desc">{item.desc}</p>
                            {item.iconList ? (
                                <div className="icon_list">
                                    {item.iconList.map((iconItem, iconIndex) => (
                                        <div className="icon_list_item" key={`icon${iconIndex}`}>
                                            {iconItem.icon}
                                            <span>{iconItem.text}</span>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    ))}
                </div>
                <div className={`app_series_sticky ${isEnterA1 ? 'isEnterA1' : ''} ${isEnterA2 ? 'isEnterA2' : ''} ${isEnterA3 ? 'isEnterA3' : ''}`}>
                    <div className="app_series_sticky_item">
                        <div className="sticky_item_position sticky01">
                            <div className="sticky_in">
                                <div className="phone">
                                    <Image alt={t('firstSeriesInApp.title1')} src={img1} className="fullImgs" layout="fill" />
                                </div>
                                <div className="dynamo">
                                    <div className="dynamo_in">
                                        <Image
                                            alt={t('firstSeriesInApp.title1')}
                                            src="/img/appDownload/pc/03_01_dynamo.png"
                                            className="fullImgs"
                                            layout="fill"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="sticky_item_position sticky02">
                            <div className="phone">
                                <Image alt={t('firstSeriesInApp.title2')} src={img2} className="fullImgs" layout="fill" />
                            </div>
                            <div className="dynamo">
                                <Image alt={t('firstSeriesInApp.title2')} src={dynamoImg[0]} className="fullImgs" layout="fill" />
                            </div>
                        </div>
                        <div className="sticky_item_position sticky03">
                            <div className="phone">
                                <Image alt={t('firstSeriesInApp.title3')} src={img3} className="fullImgs" layout="fill" />
                            </div>
                            <div className="dynamo">
                                <Image alt={t('firstSeriesInApp.title3')} src={dynamoImg[1]} className="fullImgs" layout="fill" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
