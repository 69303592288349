import { TimelineMax, TweenMax } from 'gsap';
import { FC, useEffect } from 'react';
import EFPicture from 'elem/compCommon/EFPicture';
import css from './index.module.scss';
import { useGlobal } from '@/utils/useGlobal';

export const Part3: FC = () => {
    const { useLang } = useGlobal();
    const { t } = useLang('app.upgrade');

    useEffect(() => {
        const controller = new ScrollMagic.Controller();
        const sceneA1 = new ScrollMagic.Scene({
            triggerElement: `.${css.Part3}`,
            offset: 65, //从开始点滚动多少px触发,可为负数
            triggerHook: 0, //触发元素开始进入视口时触发:1 进入,0 离开, 0.5 中间,
            duration: 400, //和滚动条绑定到一起,场景持续滚动距离为 800px
        });

        const timelineA1 = new TimelineMax();
        const tweenA1 = TweenMax.fromTo('.mask', 0.05, { opacity: 0 }, { opacity: 0.6 });
        const tweenA2 = TweenMax.fromTo('.text-wrapper .subtitle', 0.2, { opacity: 0, y: 50 }, { opacity: 1, y: 0 });
        const tweenA3 = TweenMax.fromTo('.text-wrapper .title', 0.6, { opacity: 0, y: 80 }, { opacity: 1, y: 0 });
        timelineA1.add(tweenA1).add(tweenA2, 0.1).add(tweenA3);
        sceneA1.setTween(timelineA1);
        controller.addScene(sceneA1);
    }, []);

    return (
        <div className={css.Part3}>
            <EFPicture
                src="https://ecoflow-service-us-cdn.ecoflow.com/upload/image/1712134579558/app_4_us.jpg"
                srcPad="https://ecoflow-service-us-cdn.ecoflow.com/upload/image/1712134579558/app_4_us.jpg"
                srcMobile="https://ecoflow-service-us-cdn.ecoflow.com/upload/image/1712134579558/app_4_us.jpg"
                alt={t('part3_title')}
            />
            <div className="mask"></div>
            <div className="text-wrapper">
                <p className="subtitle">{t('part3_subtitle')}</p>
                <p className="title">{t('part3_title')}</p>
            </div>
        </div>
    );
};
