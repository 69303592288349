import { useState } from 'react';
import css from './index.module.scss';
import { useGlobal } from '@/utils/useGlobal';
import { createEFPic } from '@/utils/useEFPic';
import EFArrowDown from '@/components/UI/svg/Icons/EFArrowDown';

const { EFPic } = createEFPic('/img/appDownload/app-features');
const defaultStyle = {
    icon: {
        isAlwaysShow: false, // icon总是显示
        iconPositionBetween: 80, // icon距离容器侧边边距
    },
    list: {
        imgWidth: 316,
        imgHeight: 398,
        listGap: 40, // list之间间隔
        listWidth: 540,
        listHeight: 580,
    },
    animation: {
        translateX: 400, // 容器中初始ul的偏移量
        steps: 1, // 一次滑动list的个数
        duration: 500, // 一次动画效果的时长 ms
        delay: 0, // 动画延迟时间 ms
        fill: 'forwards', // 动画执行是否应用样式 none, forwards, backwords, both, initial, inherit
        easing: 'ease', // 动画执行速度 linear,ease, ease-in, ease-out, ease-in-out, steps(int,start|end), cubic-bezier(n,n,n,n)
        iterations: 1, // 动画执行次数
    },
};

export default function SecondScreen() {
    const { useLang, useEffect, useRouter, useRef } = useGlobal();
    const { t } = useLang('app');
    const { locale } = useRouter();
    const dir = ['cn', 'de', 'fr', 'jp'].includes(locale) ? locale : 'en';
    const sliderData = [
        {
            img: [`/${dir}/${dir}-1.webp`, `/${dir}/${dir}-1.png`],
            title: t('appFeatures.monitor'),
            desc: t('appFeatures.monitor-desc'),
        },
        {
            img: [`/${dir}/${dir}-2.webp`, `/${dir}/${dir}-2.png`],
            title: t('appFeatures.control'),
            desc: t('appFeatures.control-desc'),
        },
        {
            img: [`/${dir}/${dir}-3.webp`, `/${dir}/${dir}-3.png`],
            title: t('appFeatures.rewards'),
            desc: t('appFeatures.rewards-desc'),
        },
    ];

    const animation = defaultStyle.animation;
    const list = defaultStyle.list;
    const icon = defaultStyle.icon;
    // 设置偏移量
    const [transformX, setTransformX] = useState(animation.translateX);
    // 记录热键：当前展示区域的第一个list的key
    const [reactiveKey, setReativeKey] = useState(0);
    // 记录完整显示的列表个数：向下取整

    // icon是否活性状态（非活性默认不显示）
    const [activeIconPrev, setActiveIconPrev] = useState(false);
    const [activeIconNext, setActiveIconNext] = useState(true);
    const [screenShowItemsNumber, setScreenShowItemsNumber] = useState(2);

    const listContainerRef = useRef();

    /**
     * 动画效果
     * @param steps 动画滑动几张, 默认1
     * @param moveX x轴滑动偏移量(list宽+list间距)
     * @param direction 滑动方向 1: 左滑(上一张) -1. 右滑(下一张)
     */
    const animate = (steps: number, moveX: number, direction: number) => {
        const originX = parseInt(listContainerRef?.current?.style?.transform.split('(')[1]);
        listContainerRef?.current?.animate(
            [{ transform: `translate3d(${originX}px, 0, 0)` }, { transform: `translate3d(${originX + moveX * steps * direction}px, 0, 0)` }],
            {
                duration: animation.duration,
                delay: animation.delay,
                fill: animation.fill,
                easing: animation.easing,
                iterations: animation.iterations,
            },
        );
        setTransformX(originX + moveX * steps * direction);
        setReativeKey(reactiveKey - steps * direction);
    };
    // 左滑
    const clickPrev = () => {
        if (reactiveKey > 0) {
            animate(animation.steps, list.listWidth + list.listGap, 1);
        }
    };
    // 右滑
    const clickNext = () => {
        if (reactiveKey + screenShowItemsNumber < sliderData.length) {
            animate(animation.steps, list.listWidth + list.listGap, -1);
        }
    };

    useEffect(() => {
        setActiveIconPrev(reactiveKey > 0);
        setActiveIconNext(reactiveKey + screenShowItemsNumber < sliderData.length);
    }, [reactiveKey]);

    useEffect(() => {
        const func = () => {
            const initScreenShowItemsNumber = Math.floor((globalThis.innerWidth - animation.translateX) / (list.listWidth + list.listGap));
            setScreenShowItemsNumber(initScreenShowItemsNumber);
            const pd = (window.innerWidth - 1700) / 2;
            console.log(pd);
            setTransformX(pd > 400 ? pd : 400);
            if (initScreenShowItemsNumber >= sliderData.length) {
                setActiveIconNext(false);
            } else {
                setActiveIconNext(true);
            }
        };
        func();
        // window.addEventListener('resize', () => {
        //     func();
        // });
    }, []);

    return (
        <div className={`secondScreen ${css.secondScreen}`}>
            <div className="textBox">
                <p className="title">{t('appFeatures.title')}</p>
                <p className="desc">{t('appFeatures.slogan')}</p>
            </div>
            <div className="sliderBox">
                <div className="cardSliderWrapper">
                    <div
                        className="arrowIcon prevIcon"
                        onClick={clickPrev}
                        style={{
                            display: icon.isAlwaysShow || activeIconPrev ? 'flex' : 'none',
                            width: '60px',
                            height: '60px',
                            background: 'rgba(255,255,255,0.50)',
                            borderRadius: '50%',
                            top: `${list.listHeight / 2}px`,
                            left: `${icon.iconPositionBetween}px`,
                        }}
                    >
                        <EFArrowDown className="icon" />
                    </div>
                    <div className="sliderContent" ref={listContainerRef} style={{ transform: `translate3d(${transformX}px, 0,0)` }}>
                        <ul className="listBox">
                            {sliderData.map((item: any, k: number) => (
                                <li
                                    className="list"
                                    key={k}
                                    style={{
                                        marginLeft: k === 0 ? 0 : `${parseInt(list.listGap.toString())}px`,
                                        width: list.listWidth,
                                        height: list.listHeight,
                                    }}
                                >
                                    <div className="textBox">
                                        <div className="title">{item.title}</div>
                                        <div className="desc2">{item.desc}</div>
                                    </div>
                                    <div className="imgBox" style={{ width: `${list.imgWidth}px`, height: `${list.imgHeight}px` }}>
                                        <EFPic name={item.img} alt={item.alt || item.desc} />
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div
                        className="arrowIcon nextIcon"
                        onClick={clickNext}
                        style={{
                            display: icon.isAlwaysShow || activeIconNext ? 'flex' : 'none',
                            width: '60px',
                            height: '60px',
                            background: 'rgba(255,255,255,0.50)',
                            borderRadius: '50%',
                            top: `${list.listHeight / 2}px`,
                            right: `${icon.iconPositionBetween}px`,
                        }}
                    >
                        <EFArrowDown className="icon" />
                    </div>
                </div>
            </div>
        </div>
    );
}
