export function EFCommunity(props: any) {
    return (
        <svg width="56" height="56" viewBox="0 0 64 64" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
            <g id="初稿" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="画板" transform="translate(-206.000000, -26.000000)">
                    <g id="群组" transform="translate(206.000000, 26.000000)">
                        <polygon id="路径" points="0 0 64 0 64 64 0 64"></polygon>
                        <g id="person.2.fill" transform="translate(2.000000, 13.000000)" fillRule="nonzero">
                            <rect id="矩形" fill="#000000" opacity="0" x="1.93548387" y="0" width="58.0645161" height="39"></rect>
                            <path
                                d="M28.688553,39 L55.4417312,39 C58.807131,39 60,38.0477287 60,36.1855501 C60,30.7257817 53.1200099,23.1922309 42.0651966,23.1922309 C31.0316495,23.1922309 24.1516595,30.7257817 24.1516595,36.1855501 C24.1516595,38.0477287 25.3444194,39 28.688553,39 Z M42.0864628,19.4042735 C46.6447316,19.4042735 50.5852997,15.3412203 50.5852997,10.0507674 C50.5852997,4.82381739 46.6234654,0.951219512 42.0864628,0.951219512 C37.528194,0.951219512 33.5450936,4.90846975 33.566275,10.0930882 C33.566275,15.3412203 37.5069279,19.4042735 42.0864628,19.4042735 Z M3.74884536,39 L19.9157205,39 C17.7005482,35.8045982 20.405606,29.3714306 25.0065162,25.858569 C22.6208873,24.2926141 19.5749164,23.128739 15.6130821,23.128739 C6.00666995,23.128739 0,30.1755901 0,36.0373299 C0,37.9419809 1.04371235,39 3.74884536,39 Z M15.6130821,19.8909731 C19.5962916,19.8909731 23.025599,16.3358557 23.025599,11.7437388 C23.025599,7.19393177 19.5749164,3.82921446 15.6130821,3.82921446 C11.672514,3.82921446 8.17929896,7.27858413 8.20050096,11.7859945 C8.20050096,16.3358557 11.6512478,19.8909731 15.6130821,19.8909731 Z"
                                id="形状"
                                stroke="currentColor"
                                strokeWidth="2.52"
                                strokeLinejoin="round"
                            ></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export function EFMail(props: any) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h40v40H0z" />
                <path
                    fill="currentColor"
                    d="M33.565 33.53H6.43a2.96 2.96 0 0 1-2.958-2.956V12.17l14.803 8.018c.54.291 1.13.439 1.722.439.592 0 1.184-.148 1.723-.44l14.803-8.008v18.395a2.96 2.96 0 0 1-2.958 2.956M6.43 6.47h27.135a2.96 2.96 0 0 1 2.958 2.956v.513L20.78 18.455a1.64 1.64 0 0 1-1.566 0L3.472 9.93v-.503A2.96 2.96 0 0 1 6.43 6.47m32.065 3.903v-.947a4.934 4.934 0 0 0-4.93-4.926H6.43A4.934 4.934 0 0 0 1.5 9.426v21.148A4.934 4.934 0 0 0 6.43 35.5h27.135c2.718 0 4.93-2.21 4.93-4.926V10.57c.004-.066.01-.13 0-.196"
                />
            </g>
        </svg>
    );
}

export function EFMailIT(props: any) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M34.1406 5.85937C30.2344 1.9531 25.5209 0 20 0C14.4791 0 9.76564 1.9531 5.85937 5.85937C1.95311 9.76564 0 14.4791 0 20C0 25.5209 1.95311 30.2344 5.85937 34.1406C9.76564 38.0469 14.4791 40 20 40C25.5209 40 30.2344 38.0469 34.1406 34.1406C38.0469 30.2344 40 25.5209 40 20C40 14.4791 38.0469 9.76564 34.1406 5.85937Z"
                fill="white"
                fillOpacity="0.8"
            />
            <path
                d="M20 0.5C25.3882 0.5 29.9745 2.40036 33.7871 6.21292C37.5996 10.0255 39.5 14.6118 39.5 20C39.5 25.3882 37.5996 29.9745 33.7871 33.7871C29.9745 37.5996 25.3882 39.5 20 39.5C14.6118 39.5 10.0255 37.5996 6.21293 33.7871C2.40036 29.9745 0.5 25.3882 0.5 20C0.5 14.6118 2.40036 10.0255 6.21293 6.21292C10.0255 2.40036 14.6118 0.5 20 0.5Z"
                stroke="black"
                strokeOpacity="0.03"
            />
            <path d="M30 14.2852L20 19.8566L10 14.2852" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.75 14.4883C10.75 13.8025 11.3142 13.2383 12 13.2383H28C28.6858 13.2383 29.25 13.8025 29.25 14.4883V18.7222C29.7772 18.8386 30.2801 19.0198 30.75 19.257V14.4883C30.75 12.9741 29.5142 11.7383 28 11.7383H12C10.4858 11.7383 9.25 12.9741 9.25 14.4883V26.4883C9.25 28.0025 10.4858 29.2383 12 29.2383H23.0239C22.6298 28.7893 22.2973 28.2849 22.0395 27.7383H12C11.3142 27.7383 10.75 27.1741 10.75 26.4883V14.4883Z"
                fill="black"
            />
            <path
                d="M33.5357 24.9989C33.5357 28.1351 30.9933 30.6775 27.8572 30.6775C24.721 30.6775 22.1786 28.1351 22.1786 24.9989C22.1786 21.8627 24.721 19.3203 27.8572 19.3203C30.9933 19.3203 33.5357 21.8627 33.5357 24.9989Z"
                stroke="black"
                strokeWidth="1.5"
            />
            <path d="M25.8068 21.6953H26.7839V22.621H25.8068V21.6953ZM25.8068 23.3839H26.7839V27.8153H25.8068V23.3839Z" fill="black" />
            <path
                d="M28.14 22.0553H29.1171V23.3839H30V24.1125H29.1171V26.5296C29.1171 26.9925 29.2286 27.0867 29.6057 27.0867C29.7857 27.0867 29.9657 27.0696 30 27.0525V27.8067C29.6143 27.8496 29.5029 27.8582 29.3657 27.8582C28.4314 27.8582 28.14 27.5582 28.14 26.6667V24.1125H27.4029V23.3839H28.14V22.0553Z"
                fill="black"
            />
        </svg>
    );
}
export function EFPhone(props: any) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h40v40H0z" />
                <path
                    fill="currentColor"
                    d="M8.719 3.915a.926.926 0 0 0-.66.274L6.54 5.707A8.823 8.823 0 0 0 4.243 9.73c-.425 1.642-.399 3.268.075 4.703.438 1.328 1.15 2.838 2.116 4.49a40.996 40.996 0 0 0 14.628 14.64c1.648.965 3.157 1.678 4.487 2.117 1.434.474 3.059.5 4.698.075a8.81 8.81 0 0 0 4.02-2.299l1.516-1.52a.924.924 0 0 0 .275-.66.923.923 0 0 0-.274-.658l-6.048-6.055a.933.933 0 0 0-1.32.002l-2.503 2.505a2.89 2.89 0 0 1-3.272.562c-1.488-.704-3.736-2.019-6-4.284-2.261-2.263-3.577-4.514-4.281-6.005a2.893 2.893 0 0 1 .562-3.274l2.504-2.507a.936.936 0 0 0 0-1.32L9.378 4.19a.926.926 0 0 0-.66-.274M27.956 38a9.592 9.592 0 0 1-3.017-.476c-1.457-.482-3.091-1.25-4.858-2.284A42.952 42.952 0 0 1 4.757 19.905c-1.034-1.77-1.802-3.406-2.283-4.863-.592-1.79-.63-3.797-.111-5.8.479-1.854 1.449-3.551 2.803-4.91l1.52-1.518c1.086-1.085 2.98-1.085 4.066 0L16.8 8.867a2.884 2.884 0 0 1 0 4.07l-2.504 2.506a.931.931 0 0 0-.181 1.067c.64 1.352 1.835 3.397 3.899 5.463 2.066 2.067 4.109 3.262 5.458 3.9a.93.93 0 0 0 1.068-.178l2.503-2.508.002-.001a2.88 2.88 0 0 1 4.065.001l6.047 6.055c.544.542.843 1.265.843 2.033 0 .769-.3 1.492-.843 2.035l-1.516 1.519a10.77 10.77 0 0 1-4.908 2.808c-.933.241-1.865.363-2.778.363"
                />
            </g>
        </svg>
    );
}

export function EFSupportLeft(props: any) {
    return (
        <svg width="19" height="23" viewBox="0 0 19 23" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g stroke="#03060B" strokeWidth="2" fill="none" fillRule="evenodd">
                <path d="M17.737 11.275a5 5 0 0 1-1.962 4.736l-6.79 5.054a4.984 4.984 0 0 1-3.71.936A5 5 0 0 1 1 17.054V6.946c0-1.38.56-2.63 1.464-3.536a5 5 0 0 1 6.521-.475l6.79 5.054a4.984 4.984 0 0 1 1.962 3.286z" />
                <path d="M7.134 16.134l3.71-3.839-3.71-3.839" />
            </g>
        </svg>
    );
}

export function EFWeChat(props: any) {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.371 27.047a.451.451 0 0 1-.419-.624l1.737-4.275c-2.455-2.12-3.802-4.921-3.802-7.924 0-6.205 5.888-11.253 13.125-11.253 7.236 0 13.125 5.048 13.125 11.253s-5.889 11.253-13.125 11.253a.451.451 0 0 1 0-.903c6.738 0 12.22-4.643 12.22-10.347 0-5.707-5.482-10.348-12.22-10.348-6.739 0-12.22 4.638-12.22 10.345 0 2.83 1.324 5.47 3.727 7.438a.453.453 0 0 1 .131.522l-1.538 3.785 6.34-1.386a.453.453 0 0 1 .194.885l-7.178 1.567c-.032.006-.065.012-.097.012zm8.256-12.797a.883.883 0 0 1 0-1.764.883.883 0 0 1 0 1.764zm-.01-.866c0 .094.074.15.15.15l-.15-.15zm8.116.866a.883.883 0 0 1 0-1.764.883.883 0 0 1 0 1.764zm-.01-.866c0 .094.075.15.15.15l-.15-.15z"
                stroke="#03060B"
                fill="#000"
                fillRule="nonzero"
            />
        </svg>
    );
}
