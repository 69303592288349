import { useRef, forwardRef, useImperativeHandle } from 'react';
import isNil from 'lodash/isNil';
import { getFileInfo } from '@/utils';
import { useGlobal } from '@/utils/useGlobal';
import { BackdropReplay } from '@/components/UI/svg/Icons/EFReplayBig';
import { handleDynamicPath } from '@/utils/handleDynamicPath';

interface EFVideoParams {
    sources?: string[] | null;
    className?: string;
    poster?: string;
    text?: string;
    autoPlay?: boolean;
    loop?: boolean;
    /** 是否展示重播按钮 */
    showReplay?: boolean;
    width?: string | number;
    height?: string | number;
    playsInline?: boolean;
    muted?: boolean;
    preload?: string;
    /** 配置视频播放一次后展示在哪一帧 */
    endFrame?: number;
    playBar?: boolean;
    // 重播按钮样式自定义
    replayIcon?: any;
    // 替换replayIconSVG  SVG组件
    resetReplayIcon?: React.ReactNode;
    onEnded?: () => void;
}

const EFVideo = (
    {
        sources = null,
        className = '',
        poster = '',
        text = '',
        autoPlay = true,
        loop = true,
        showReplay = false,
        playBar = false,
        replayIcon,
        onEnded,
        ...rest
    }: EFVideoParams,
    ref: any,
) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const { isMobile, useEffect, useState } = useGlobal();
    const urlRegExp = /^(http|blob)/i;
    const [videoSources, setVideoSources] = useState(sources);
    // 创建一个状态来保存视频播放进度
    const [progress, setProgress] = useState(0);
    const canPlay = useRef<boolean>(false);
    const playBarRef = useRef(null);

    const replayStyle = isMobile
        ? {
              width: '.3rem',
              height: '.3rem',
              fontSize: '.3rem',
              bottom: '0.16rem',
              right: '0.16rem',
          }
        : {
              fontSize: '40px',
              bottom: '20px',
              right: '20px',
          };

    if (isMobile && autoPlay === false) {
        autoPlay = true;
        canPlay.current = true;
    }

    useImperativeHandle(ref, () => ({
        current: videoRef.current,
        play: () => videoRef.current?.play(),
        seek: (position: number) => videoRef.current?.seek(position),
        pause: () => videoRef.current?.pause(),
        currentTime: videoRef.current?.currentTime,
        setCurrentTime: (time: number) => {
            if (videoRef.current) {
                videoRef.current.currentTime = time;
            }
        },
    }));

    const updateProgressBar = () => {
        if (videoRef.current?.paused) return;
        // 获取视频当前时间和总时长
        const currentTime = videoRef.current?.currentTime || 0;
        const duration = videoRef.current?.duration || 1;
        // 计算进度百分比
        const progressPercent = (currentTime / duration) * 100;
        // 更新进度条
        setProgress(progressPercent);
        // 每页面刷新 更新一次进度条
        requestAnimationFrame(updateProgressBar);
    };

    useEffect(() => {
        // 处理移动端的autoPlay
        if (isMobile && canPlay.current) {
            const video: any = videoRef.current;
            video.pause();
            const handlePauseFn = () => {
                video.pause();
                video.removeEventListener('canplay', handlePauseFn);
            };
            video.addEventListener('canplay', handlePauseFn);
        }

        if (typeof window !== 'undefined') {
            // 安卓手机端火狐浏览器 无法播放webm格式视频问题
            const agent = navigator.userAgent.toLowerCase();
            const isFF = agent.indexOf('firefox') >= 0;
            const isAndroid = agent.indexOf('android') > -1 || agent.indexOf('adr') > -1;
            if (isFF && isAndroid && isMobile) {
                const result: string[] = [];
                result.push(videoSources?.find((video: string) => video.endsWith('.mp4')));
                result.push(videoSources?.find((video: string) => video.endsWith('.webm')));
                setVideoSources(result);
            }
        }

        // 设置视频停止播放时的展示
        if (!isNil(rest.endFrame) && videoRef.current) {
            const curVideo = videoRef.current;
            curVideo.addEventListener('ended', () => {
                curVideo.currentTime = rest.endFrame || 0;
            });
        }
    }, []);

    useEffect(() => {
        if (playBar) {
            videoRef.current?.addEventListener('play', updateProgressBar);
            return () => {
                videoRef.current?.removeEventListener('play', updateProgressBar);
            };
        }
    }, []);

    useEffect(() => {
        // 延迟加载对video赋予src资源
        if (Array.isArray(sources) && videoSources !== sources) {
            setVideoSources(sources);
        }
    }, [sources]);

    return (
        <>
            <video
                ref={videoRef}
                className={`${className} ef-video`}
                poster={poster ? handleDynamicPath(poster) : poster}
                muted
                autoPlay={autoPlay}
                loop={loop}
                playsInline
                // eslint-disable-next-line react/no-unknown-property
                webkit-playsinline="true"
                // eslint-disable-next-line react/no-unknown-property
                x5-playsinline="true"
                // eslint-disable-next-line react/no-unknown-property
                x5-video-player-type="h5-page"
                onEnded={onEnded}
                {...rest}
            >
                {Array.isArray(videoSources) &&
                    videoSources.map((value: string, index: number) => {
                        const info = getFileInfo(value);
                        const src = urlRegExp.test(value) || value.startsWith('/responsive/') ? value : handleDynamicPath(value);
                        return <source key={index} src={src} type={`video/${info.ext}`} />;
                    })}
                {text}
            </video>
            {showReplay && (
                <div
                    className="icon-replay"
                    onClick={() => videoRef.current?.play()}
                    style={{
                        width: '44px',
                        height: '44px',
                        position: 'absolute',
                        cursor: 'pointer',
                        zIndex: 5,
                        borderRadius: '50%',
                        ...replayStyle,
                    }}
                >
                    {rest.resetReplayIcon ? rest.resetReplayIcon : <BackdropReplay {...replayIcon} />}
                </div>
            )}
            {playBar && (
                <div
                    ref={playBarRef}
                    style={{
                        position: 'absolute',
                        height: '2px',
                        zIndex: 5,
                        bottom: 0,
                        backgroundColor: '#019F54',
                        willChange: 'width',
                        width: `${progress}%`,
                    }}
                ></div>
            )}
        </>
    );
};

export default forwardRef(EFVideo);
