import { useEffect } from 'react';
import { TimelineMax, TweenMax } from 'gsap';
import * as deviceDetect from 'react-device-detect';
import styled from './index.module.scss';
import { useGlobal } from '@/utils/useGlobal';
import { URL } from '@/constants';
import EFVideo from '@/components/UI/EFVideo';

const { APP_ANDROID, APP_IOS, APK_CN, APK_OVERSEAS, APP_IOS_CN } = URL;

export default function FirstScreen({ fileUrl, UPGRADE_SITE }: any) {
    const { useLang, Link, Image, useRouter, useState } = useGlobal();
    const { t } = useLang('app');
    const { t: s } = useLang('shared');
    const { locale } = useRouter();
    const [isIOS, setIsIOS] = useState(false);
    const [kvSrc, setKvSrc] = useState(['/img/appDownload/h5/7d7d80b655188f312c4c003c1303c3b6.webm', '/img/appDownload/h5/d46e850ab5d36f1a4a640cc6c1ed8e5e.mp4']);

    useEffect(() => {
        if (UPGRADE_SITE.includes(locale)) {
            setKvSrc(['https://ecoflow-service-us-cdn.ecoflow.com/upload/video/1712815269777/app_kv_h5.mp4']);
        }
        setIsIOS(deviceDetect.isIOS);
        const controller = new ScrollMagic.Controller();
        const sceneA1 = new ScrollMagic.Scene({
            triggerElement: `.${styled.firstScreenH5}`,
            offset: 50, //从开始点滚动多少px触发,可为负数
            triggerHook: 0, //触发元素开始进入视口时触发:1 进入,0 离开, 0.5 中间,
            duration: '80%', //和滚动条绑定到一起,场景持续滚动距离为 800px
        });
        const timelineA1 = new TimelineMax();
        const tweenA1 = TweenMax.fromTo('.video_box_mask', 0.3, { opacity: 0 }, { opacity: 0.8 });
        const tweenA2 = TweenMax.fromTo('.video_box_context .title', 0.3, { opacity: 0, y: 100 }, { opacity: 1, y: 0 });
        const tweenA3 = TweenMax.fromTo('.video_box_context .desc', 0.3, { opacity: 0, y: 100 }, { opacity: 1, y: 0 });
        const tweenA4 = TweenMax.fromTo('.video_box_context .link', 0.3, { opacity: 0, y: 100 }, { opacity: 1, y: 0 });
        const tweenA5 = TweenMax.fromTo('.video_box_context .play-item', 0.3, { opacity: 0, y: 100 }, { opacity: 1, y: 0 });
        timelineA1.add(tweenA1).add(tweenA2, 0).add(tweenA3).add(tweenA4).add(tweenA5);
        sceneA1.setTween(timelineA1);
        controller.addScene(sceneA1);
    }, []);

    const getAppDataLayer = (type: string) => ({
        event: 'uaEvent',
        event_name: 'ecoflow_app',
        button_name: type,
    });

    let appStoreVal = '/img/appDownload/pc/applestore.png';
    let googlePayVal = '/img/appDownload/pc/googleplay.png';
    if (!['us', 'au', 'eu', 'ca', 'uk', 'za', 'cis', 'br'].includes(locale)) {
        appStoreVal = `/img/appDownload/pc/applestore_${locale}.png`;
        googlePayVal = `/img/appDownload/pc/googleplay_${locale}.png`;
    }

    return (
        <div className={`${styled.firstScreenH5}`}>
            <div className="video_box">
                <EFVideo sources={kvSrc} className="video" poster="/img/appDownload/h5/99d51e481e9b3917855c2c58b7f8bb32.png" />
                <div className="video_box_mask"></div>
                <div className="video_box_context">
                    <h3 className="title">{t('firstScreen.title')}</h3>
                    <p className="desc">{t('firstScreen.desc')}</p>
                    <div className="link">
                        <Link
                            href={locale === 'cn' ? APK_CN : APK_OVERSEAS}
                            ariaLabel={s('site_support_download_apk')}
                            selfWin
                            dataLayer={getAppDataLayer('Download the EcoFlow App apk file')}
                        >
                            {t('firstScreen.left-button')} &gt;
                        </Link>
                    </div>
                    <div className="link link2">
                        <Link href={fileUrl} ariaLabel={'more'} newWin>
                            {t('firstScreen.right-button')} &gt;
                        </Link>
                    </div>
                    <div className="play-item">
                        {isIOS ? (
                            <Link href={locale === 'cn' ? APP_IOS_CN : APP_IOS} ariaLabel={'ios'} dataLayer={getAppDataLayer('App Store')}>
                                <Image src={appStoreVal} layout="fill" alt={'ios'} />
                            </Link>
                        ) : (
                            <div className="hide-cn">
                                <Link href={APP_ANDROID} ariaLabel={'android'} dataLayer={getAppDataLayer('Google Play')}>
                                    <Image src={googlePayVal} layout="fill" alt={'android'} />
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
