import css from './index.module.scss';
import { useGlobal } from '@/utils/useGlobal';

export default function Bluetooth({ data }: any) {
    const { Image, useLang } = useGlobal();
    const { t } = useLang('app');

    return (
        <div className={css.bluetooth}>
            <div className="bluetooth">
                <div className="main-title">{t('quickGuideChild.content-title')}</div>
                <div className="bluetooth-title">{data.title}</div>
                <div className="common-desc desc1">{data.desc1}</div>
                <div className="common-desc desc2">{data.desc2}</div>
                <div className="step-box">
                    {data.step?.map((item: any, index: number) => (
                        <div key={index} className="step-item">
                            <div className="step-img">
                                <Image layout="fill" className="fullImgs" src={item.img} />
                            </div>
                            <div className="step-desc">{item.title}</div>
                        </div>
                    ))}
                </div>
                <div className="note-box">
                    <div className="note">{t('quickGuideChild.note')}</div>
                    <div className="note-desc">{data.note}</div>
                </div>
            </div>
        </div>
    );
}
