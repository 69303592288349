'use client';
import parseUri from './parseUri';

/**
 * 生成YouTube iframe播放器URL
 * @param rawUrl YouTube分享链接
 * 类似: https://www.youtube.com/watch?v=ozVzv0jo_BM 地址栏粘贴
 *      https://youtu.be/ozVzv0jo_BM  点击分享
 *      https://www.youtube.com/embed/gRlqMXwHKBg iframe插入链接
 */
export default function generatorPlayUrl(rawUrl: string) {
    const params = parseUri(rawUrl);
    let {
        params: { v: id },
    } = params;

    const { hostname, pathname } = params;

    if (!hostname.includes('youtube.com') && !hostname.includes('youtu.be')) return { url: rawUrl, isYouTubeVideo: false };

    if (!id) {
        const pathArr = pathname.split('/');
        const leng = pathArr.length;
        if (leng > 0) {
            id = pathArr[leng - 1] || pathArr[leng - 2];
        }
    }

    if (id) {
        return {
            url: `https://www.youtube.com/embed/${id}`,
            imageUrl: `https://i.ytimg.com/vi/${id}/hqdefault.jpg`,
            isYouTubeVideo: true,
        };
    }

    return { url: rawUrl, isYouTubeVideo: false, imageUrl: `/img/search/2461bcec7e9c85a041ff2f95d5e476ea.png` };
}
