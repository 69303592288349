import css from './index.module.scss';
import { useGlobal } from '@/utils/useGlobal';
import {
    EFTabIotNotSelectH5,
    EFTabIotSelectH5,
    EFTabApNotSelectH5,
    EFTabApSelectH5,
    EFTabBluetoothNotSelectH5,
    EFTabBluetoothSelectH5,
} from '@/components/UI/svg/appDownload';
import EFVideo from '@/components/UI/EFVideo';

export default function IotComp() {
    const { useLang, useState, useEffect } = useGlobal();
    const { t } = useLang('app');
    const [activeTab, setActiveTab] = useState(0);
    const [selectTab, setSelectTab] = useState({});

    const data = [
        {
            title: t('quickGuideChild.tab1'),
            desc: t('quickGuideChild.iot-desc'),
            video: ['/video/app/h5/547c5c6f80bf0f65cc5e1cc798799b2c.mp4', '/video/app/h5/cf202664d2055945e30c8793b7c9e97f.webm'],
            svg: <EFTabIotNotSelectH5 width="0.2rem" height="0.2rem" />,
            selectSvg: <EFTabIotSelectH5 width="0.2rem" height="0.2rem" />,
        },
        {
            title: t('quickGuideChild.tab3'),
            desc: t('quickGuideChild.direct-desc'),
            video: ['/video/app/h5/e99748d8b9a0f7719ca476acd24d913c.mp4', '/video/app/h5/2aa3be67eec78292a15f377a6adafb6c.webm'],
            svg: <EFTabApNotSelectH5 width="0.2rem" height="0.2rem" />,
            selectSvg: <EFTabApSelectH5 width="0.2rem" height="0.2rem" />,
        },
        {
            title: t('quickGuideChild.tab2'),
            desc: t('quickGuideChild.bluetooth-desc'),
            svg: <EFTabBluetoothNotSelectH5 width="0.2rem" height="0.2rem" />,
            video: ['/video/app/h5/cf5ce1bfed613fbe54868fb7b899ef14.mp4', '/video/app/h5/a97178a8c1f9321444e93ada87020e6d.webm'],
            selectSvg: <EFTabBluetoothSelectH5 width="0.2rem" height="0.2rem" />,
        },
    ];

    const clickTab = (index: number) => {
        setActiveTab(index);
        setSelectTab(data[index]);
    };

    useEffect(() => {
        setSelectTab(data[activeTab]);
    }, []);

    return (
        <div className={css.iotComp}>
            <div className="iot-comp">
                <div className="tab-box">
                    {data.map((item: any, index) => (
                        <div className={`item-box ${activeTab === index ? 'active' : ''}`} key={index} onClick={() => clickTab(index)}>
                            {activeTab === index ? item.selectSvg : item.svg}
                            <span className={`tab-item tab-item${index + 1}`}>{item.title}</span>
                        </div>
                    ))}
                </div>
                <div className="box">
                    <div className="box-title">{t('quickGuideChild.tree1-title')}</div>
                    <div className="desc">{selectTab.desc}</div>
                    <div className="video-box">
                        <EFVideo key={selectTab.title} sources={selectTab.video} className="video" />
                    </div>
                </div>
            </div>
        </div>
    );
}
