import { useMemo } from 'react';
import { ScrollMagicPluginGsap } from 'scrollmagic-plugin-gsap';
import { TweenMax, TimelineMax } from 'gsap';
import styled from './index.module.scss';
import PageLayout from '@/components/PageLayout';
import { redirectTo404 } from '@/utils';
import { useGlobal } from '@/utils/useGlobal';
import DownloadNav from '@/components/AppDownload/DownloadNav';
import QuickGuidePc from '@/components/AppDownload/QuickGuide';
import QuickGuideH5 from '@/componentsH5/AppDownload/QuickGuide';
import TutorialVideos from '@/components/AppDownload/TutorialVideos';
import OverViewPc from '@/components/AppDownload/OverView';
import OverViewH5 from '@/componentsH5/AppDownload/OverView';
import ScrollMagicFn from '@/utils/ScrollMagic';
import { getDownloadInfo, getIosAppVersion } from '@/service';
import { appLocaleMap } from '@/utils/locals';
import SEOlinksA from '@/components/SEOlinksA';
import seoconfig from '@/seoconfig/app';

export default function AppDownload({ SEO, lang }: any) {
    if (typeof window !== 'undefined') {
        window.ScrollMagic = ScrollMagicFn();
        ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);
    }

    const { useState, isMobile, useLang, useEffect, useRouter } = useGlobal();
    const { t } = useLang('app');
    const { locale } = useRouter();
    const OverView = isMobile ? OverViewH5 : OverViewPc;
    const QuickGuide = isMobile ? QuickGuideH5 : QuickGuidePc;
    const [tabsel, setTabsel] = useState('overview');
    const [themeColor, setThemeColor] = useState('black');
    const [iosAppVersionInfo, setIosAppVersionInfo] = useState({});
    const [videoList, setVideoList] = useState([]);
    const [fileUrl, setFileUrl] = useState('');

    /**
     * 获取下载列表
     * @params params 请求参数
     */
    const getResource = async () => {
        const reqLocale = ['us', 'za'].includes(locale) ? 'us' : locale;
        try {
            const res = await getDownloadInfo({
                page: 1,
                size: 100,
                productCatagoryIds: '1519572802501177346',
            });
            if (res.code === '0') {
                const data = res.data?.content || [];
                const fileObj = data.find((item: any) => item.lang === appLocaleMap[reqLocale] && item.classify === 'MANUAL');
                setFileUrl(fileObj?.fileUrl);
                const list = data.filter((item: any) => item.lang === appLocaleMap[reqLocale] && item.classify === 'VIDEO_COURSE');
                setVideoList(list);
            }
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        const renderFrostNav = () => {
            let initScrollTop = 0;
            const regionPopupEl = document.querySelector('.regionPopup') as HTMLElement;
            if (regionPopupEl) {
                initScrollTop += regionPopupEl?.offsetHeight;
            }
            const headerEl = document.querySelector('.header') as HTMLElement;
            if (headerEl) {
                initScrollTop += headerEl?.offsetHeight;
            }
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            const productNav = document.querySelectorAll('.down_nav')[0];
            productNav?.classList.toggle('frost', scrollTop > initScrollTop);
        };

        // 毛玻璃效果
        document.addEventListener('scroll', renderFrostNav);
        return () => {
            document.removeEventListener('scroll', renderFrostNav);
        };
    }, []);

    const handleDownLoad = () => {
        setTabsel('overview');
        setTimeout(() => {
            const scrollHeight = document.body.scrollHeight; // 内容总高度
            const height = isMobile ? scrollHeight * 0.8 : scrollHeight - 1800;
            window.scrollTo(0, height);
        }, 0);
    };

    useEffect(() => {
        getResource();
        getIosAppVersion(locale).then((res) => {
            if (Array.isArray(res?.results)) {
                setIosAppVersionInfo({
                    iosAppVersion: res.results[0]?.version || '3.4.0',
                    minOSVersion: res.results[0]?.minimumOsVersion || '11.0',
                });
            }
        });
    }, []);

    const contentHTML = useMemo(() => {
        switch (tabsel) {
            case 'overview':
                setThemeColor('black');
                return (
                    <OverView
                        clickHere={() => {
                            window?.scrollTo({ top: 0 });
                            setTabsel('quickGuide');
                        }}
                        iosAppVersionInfo={iosAppVersionInfo}
                        fileUrl={fileUrl}
                    />
                );
            case 'quickGuide':
                setThemeColor('#f8f8f8');
                return <QuickGuide />;
            case 'tutorialVideos':
                setThemeColor('#f8f8f8');
                return <TutorialVideos videoList={videoList} />;
        }
    }, [tabsel, iosAppVersionInfo, fileUrl, videoList]);

    return (
        <PageLayout isNoFixed={true} className={`${styled.appDownload}`} theme={''} style={{ backgroundColor: themeColor }}>
            <SEO title={lang.seoTitle} description={lang.seoDesc} />
            <SEOlinksA locale={locale} list={seoconfig.include} pathname={seoconfig.url} />
            <DownloadNav
                className="down_nav"
                title={t('downloadSection.ecoflow-app')}
                theme={themeColor}
                handleBtnOperate={handleDownLoad}
                callback={setTabsel}
                curActive={tabsel}
            />
            {contentHTML}
        </PageLayout>
    );
}

// 以下两个方法不可删除，否则会重复触发 _app.js 中 getInitialProps 中的请求
export async function getServerSideProps({ locale }: any) {
    const whiteLocaleList = ['us', 'jp', 'au', 'ca', 'eu', 'uk', 'cn', 'fr', 'de', 'za', 'cis', 'br'];
    if (!whiteLocaleList.includes(locale)) {
        return redirectTo404();
    }
    return {
        props: {},
    };
}
