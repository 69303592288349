import Image from 'next/image';
import style from './index.module.scss';
import { useGlobal } from '@/utils/useGlobal';
import { handleDynamicPath } from '@/utils/handleDynamicPath';

const isTest = process.env.NEXT_PUBLIC_LANG_EDIT === 'on';

interface EFPicProps {
    src: Array<string>;
    className?: string;
    alt?: string;
    'aria-label'?: string;
    loading?: 'eager' | 'lazy';
    'data-banner-name'?: string;
    'data-position'?: string;
}

const getCheckStr = (url1: string, url2: string) => {
    const datasrc1 = url1.split('/').pop()?.split('.')[0] || '';
    const datasrc2 = url2.split('/').pop()?.split('.')[0] || '';

    let str = '';
    if (datasrc1.length === 32) {
        str += '1';
    } else {
        str += datasrc1;
    }
    if (datasrc2.length === 32) {
        str += '2';
    } else {
        str += datasrc2;
    }
    return str;
};

// 11-22 只用一张图片策略
export default function EFPic({ src, className = '', alt = '', loading = 'lazy', ...rst }: EFPicProps): JSX.Element {
    const { isSupportWebp } = useGlobal();
    const src1 = src[0] || '';
    const src2 = src[1] || '';
    const isAbsoluteUrl = /^(http|blob)/i.test(String(src1));
    const url1 = !isAbsoluteUrl ? handleDynamicPath(src1) : src1;
    const url2 = !isAbsoluteUrl ? handleDynamicPath(src2) : src2;

    // 用于脚本检测
    const datasrc = getCheckStr(url1, url2);

    let url = isSupportWebp ? url1 : url2;

    // 用于提供一套测试不支持webp格式的环境
    if (isTest) {
        url = url2;
    }

    return (
        <div
            className={`${style.EFPic} ${isAbsoluteUrl ? 'ef-picture_upload' : ' ef-picture_build'} ${className}`}
            data-src={datasrc}
            data-banner-name={rst['data-banner-name']}
            data-position={rst['data-position']}
        >
            <Image className="fullImgs" unoptimized alt={alt || 'EcoFlow'} layout="fill" loading={loading} src={url} aria-label={rst['aria-label']} />
        </div>
    );
}
