import { TimelineMax, TweenMax } from 'gsap';
import { useState } from 'react';
import styled from './index.module.scss';
import { useGlobal } from '@/utils/useGlobal';
import { URL } from '@/constants';
import EFVideo from '@/components/UI/EFVideo';

export default function FirstScreen({ fileUrl, UPGRADE_SITE }: any) {
    const { useEffect, Link, Image, useLang, useRouter } = useGlobal();
    const { t } = useLang('app');
    const { APP_ANDROID, APP_IOS, APK_OVERSEAS, APP_IOS_CN, APP_IOS_RU, APK_CN } = URL;
    const { locale } = useRouter();
    const [kvSrc, setKvSrc] = useState(['/img/appDownload/pc/f24845dcc2f905eb09e8ce5623d87292.webm', '/img/appDownload/pc/2a0dc2ad25948eb8e495ee82bada9782.mp4']);
    const [appStore, setAppStore] = useState('/img/appDownload/pc/applestore.png');
    const [googlePay, setGooglePay] = useState('/img/appDownload/pc/googleplay.png');

    useEffect(() => {
        if (UPGRADE_SITE.includes(locale)) {
            setKvSrc(['https://ecoflow-service-us-cdn.ecoflow.com/upload/video/1712815247710/app_kv_pc.mp4']);
        }
        const controller = new ScrollMagic.Controller();
        const sceneA1 = new ScrollMagic.Scene({
            triggerElement: `.${styled.firstScreen}`,
            offset: 65, //从开始点滚动多少px触发,可为负数
            triggerHook: 0, //触发元素开始进入视口时触发:1 进入,0 离开, 0.5 中间,
            duration: 200, //和滚动条绑定到一起,场景持续滚动距离为 800px
        });

        const timelineA1 = new TimelineMax();
        const tweenA1 = TweenMax.fromTo('.down_mask', 0.05, { opacity: 0 }, { opacity: 0.8 });
        const tweenA2 = TweenMax.fromTo('.down_box .down_box_title', 0.2, { opacity: 0, y: 50 }, { opacity: 1, y: 0 });
        const tweenA3 = TweenMax.fromTo('.down_box .down_box_content', 0.6, { opacity: 0, y: 100 }, { opacity: 1, y: 0 });
        timelineA1.add(tweenA1).add(tweenA2, 0.1).add(tweenA3);
        sceneA1.setTween(timelineA1);
        controller.addScene(sceneA1);

        if (['us', 'au', 'eu', 'ca', 'uk', 'za', 'cis', 'br'].includes(locale)) {
            setAppStore('/img/appDownload/pc/applestore.png');
            setGooglePay('/img/appDownload/pc/googleplay.png');
        } else {
            setAppStore(`/img/appDownload/pc/applestore_${locale}.png`);
            setGooglePay(`/img/appDownload/pc/googleplay_${locale}.png`);
        }
    }, []);

    const getAppDataLayer = (type: string) => ({
        event: 'uaEvent',
        event_name: 'ecoflow_app',
        button_name: type,
    });

    return (
        <div className={`${styled.firstScreen}`}>
            <div className="video_box">
                <div className="video_position">
                    <EFVideo sources={kvSrc} />
                </div>
                <div className="down_mask"></div>
                <div className="down_box">
                    <h3 className="down_box_title">{t('firstScreen.title')}</h3>
                    <div className="down_box_content">
                        <p>{t('firstScreen.desc')}</p>
                        <div className="down_box_link">
                            <Link href={locale === 'cn' ? APK_CN : APK_OVERSEAS} ariaLabel={t('site_support_download_apk')} selfWin>
                                {t('firstScreen.left-button')} &gt;
                            </Link>
                            <Link href={fileUrl || 'https://websiteoss.ecoflow.com/cms/upload/2022/10/10/1677531949_1665383115587.pdf'} ariaLabel={'more'}>
                                {t('firstScreen.right-button')} &gt;
                            </Link>
                        </div>
                        <div className="down_box_btn">
                            <div className="play-item">
                                <Link
                                    href={locale === 'cn' ? APP_IOS_CN : locale === 'cis' ? APP_IOS_RU : APP_IOS}
                                    ariaLabel={'ios'}
                                    dataLayer={getAppDataLayer('App Store')}
                                >
                                    <Image src={appStore} width={210} height={60} alt={'ios'} />
                                </Link>
                            </div>
                            <div className="play-item hide-cn">
                                <Link href={APP_ANDROID} ariaLabel={'android'} dataLayer={getAppDataLayer('Google Play')}>
                                    <Image src={googlePay} width={210} height={60} alt={'android'} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
