import style from './index.module.scss';
import { useGlobal } from '@/utils/useGlobal';
import { handleDynamicPath } from '@/utils/handleDynamicPath';

const isTest = process.env.NEXT_PUBLIC_LANG_EDIT === 'on';

interface EFFigureProps {
    src: Array<string>;
    width?: string;
    height?: string;
    className?: string;
}
/**
 * 图片组件-自动转换图片地址 webp or jpg
 */
export default function EFFigure({ src = [], width = 'auto', height = '100%', className = '' }: EFFigureProps): JSX.Element {
    const { isSupportWebp } = useGlobal();
    const src1 = src[0] || '';
    const src2 = src[1] || '';
    const isAbsoluteUrl = /^(http|blob)/i.test(String(src1));
    const url1 = !isAbsoluteUrl ? handleDynamicPath(src1) : src1;
    const url2 = !isAbsoluteUrl ? handleDynamicPath(src2) : src2;
    const datasrc1 = url1.split('/').pop();
    const datasrc2 = url2.split('/').pop();

    let baseSrc = isSupportWebp ? url1 : url2;

    if (isTest) {
        baseSrc = url2;
    }

    return (
        <figure
            className={`ef-picture ${style.EFFigure} ${isAbsoluteUrl ? 'ef-picture_upload' : ' ef-picture_build'} ${className} `}
            data-src1={datasrc1}
            data-src2={datasrc2}
            style={{
                height: `${height}`,
                backgroundImage: `url(${baseSrc})`,
                backgroundSize: `${width} ${height}`,
            }}
        />
    );
}
