import { Swiper, SwiperSlide } from 'swiper/react';
import styled from './index.module.scss';
import { useGlobal } from '@/utils/useGlobal';
import { createEFPic } from '@/utils/useEFPic';
import EFArrowDown from '@/components/UI/svg/Icons/EFArrowDown';

const { EFPic } = createEFPic('/img/appDownload/app-features');

export default function SecondScreen() {
    const { useLang, useRouter, useState, useEffect } = useGlobal();
    const { locale } = useRouter();
    const { t } = useLang('app');
    const [winW, setWinW] = useState(375);

    const dir = ['cn', 'de', 'fr', 'jp'].includes(locale) ? locale : 'en';
    const swiperList = [
        {
            img: [`/${dir}/${dir}-1.webp`, `/${dir}/${dir}-1.png`],
            title: t('appFeatures.monitor'),
            desc: t('appFeatures.monitor-desc'),
        },
        {
            img: [`/${dir}/${dir}-2.webp`, `/${dir}/${dir}-2.png`],
            title: t('appFeatures.control'),
            desc: t('appFeatures.control-desc'),
        },
        {
            img: [`/${dir}/${dir}-3.webp`, `/${dir}/${dir}-3.png`],
            title: t('appFeatures.rewards'),
            desc: t('appFeatures.rewards-desc'),
        },
    ];

    const len = swiperList.length;
    const [swiper, setSwiper] = useState({ activeIndex: 0 });
    const [active, setActive] = useState(0);
    // icon是否活性状态（非活性默认不显示）
    const [activeIconPrev, setActiveIconPrev] = useState(false);
    const [activeIconNext, setActiveIconNext] = useState(true);

    const onSlideChange = (swiper: any) => {
        setActive(swiper?.realIndex || swiper.activeIndex % len);
    };
    const onPrev = () => {
        const preIndex = swiper?.realIndex - 1;
        swiper?.slideToLoop(preIndex < 0 ? 2 : preIndex);
        setActive(swiper?.realIndex);
    };
    const onNext = () => {
        const nextIndex = (swiper?.realIndex + 1) % len;
        swiper?.slideToLoop(nextIndex);
        setActive(swiper?.realIndex);
    };
    useEffect(() => {
        setActiveIconPrev(active > 0);
        setActiveIconNext(active < swiperList.length - 1);
    }, [active]);

    return (
        <div className={`${styled.secondScreen}`}>
            <div className="textBox">
                <p className="title">{t('appFeatures.title')}</p>
                <p className="desc">{t('appFeatures.slogan')}</p>
            </div>
            <div className="swiper_box">
                <Swiper
                    slidesPerView={'auto'}
                    initialSlide={0}
                    preloadImages={false}
                    lazy
                    centeredSlides
                    grabCursor
                    spaceBetween={(winW / 375) * 10}
                    className="swiper_controller"
                    onSwiper={(swiper: any) => setSwiper(swiper)}
                    onSlideChange={(swiper: any) => onSlideChange(swiper)}
                >
                    {swiperList.map((item, index) => (
                        <SwiperSlide key={index}>
                            <div className="textBox">
                                <div className="title">{item.title}</div>
                                <div className="desc2">{item.desc}</div>
                            </div>
                            <div className="imgBox">
                                <EFPic name={item.img} alt={item.desc} />
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <ul className="paginations">
                    {swiperList.map((_item: any, k: number) => (
                        <li className={`pagination-item ${k === active ? 'active' : ''}`} key={k}></li>
                    ))}
                </ul>
                <div
                    className="arrowIcon prevIcon"
                    onClick={onPrev}
                    style={{
                        display: activeIconPrev ? 'flex' : 'none',
                        width: '52px',
                        height: '52px',
                        background: 'rgba(255,255,255,0.50)',
                        borderRadius: '50%',
                    }}
                >
                    <EFArrowDown className="icon" />
                </div>
                <div
                    className="arrowIcon nextIcon"
                    onClick={onNext}
                    style={{
                        display: activeIconNext ? 'flex' : 'none',
                        width: '52px',
                        height: '52px',
                        background: 'rgba(255,255,255,0.50)',
                        borderRadius: '50%',
                    }}
                >
                    <EFArrowDown className="icon" />
                </div>
            </div>
        </div>
    );
}
