import EFFigure from '@/components/UI/EFFigure';
import EFPic from '@/components/UI/EFPic';

const useEFPic = (path = '') => ({
    EFPic: (props: any) => {
        const srcArr = [];
        if (!props.name) {
            return <></>;
        }
        if (Array.isArray(props.name)) {
            props.name.forEach((item: string) => srcArr.push(path + item));
        } else {
            srcArr.push(path + props.name);
        }
        return <EFPic {...props} src={srcArr} />;
    },
    EFFigure: (props: any) => {
        const srcArr = [];
        if (!props.name) {
            return <></>;
        }
        if (Array.isArray(props.name)) {
            props.name.forEach((item: string) => srcArr.push(path + item));
        } else {
            srcArr.push(path + props.name);
        }
        return <EFFigure {...props} src={srcArr} />;
    },
});

export const createEFPic = useEFPic;

export default useEFPic;
