'use client';

/**
 * @function parseUri(uri) => uriObject
 * @param {String} uri
 * parseUri('app://navigate:3000/path/to/post/POST_ID?a=123&b[]=xxx#hash/path?x=xxx')
 * => Object {
 *   "protocol": "app:",
 *   "host": "navigate:3000",
 *   "pathname": "/path/to/post/POST_ID",
 *   "search": "?a=123&b[]=xxx",
 *   "hash": "#hash/path?x=xxx",
 *   "hostname": "navigate",
 *   "port": "3000",
 *   "href": "app://navigate:3000/path/to/post/POST_ID?a=123&b[]=xxx#hash/path?x=xxx",
 *   "origin": "app://navigate:3000"
 * }
 */

// const uriRegx = /^(.*:)\/\/([A-Za-z0-9\-\.]+)(:[0-9]+)?(.*)$/;
// const uriRegx = /^((.*:)\/)?\/?([^:\/\s]+)(:([0-9]+))?((\/\w+)*\/)([\w\-\.]+[^#?\s]+)(.*)?(#[\w\-]+)?$/;
import _defineProperty from "/var/jenkins_home/workspace/ef-website_staging/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _slicedToArray from "/var/jenkins_home/workspace/ef-website_staging/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
var uriRegx = /^(([^:/?#]+):)?(\/\/([^/?#]*))?([^?#]*)(\?([^#]*))?(#(.*))?/;
var uriRegxHash = {
  1: 'protocol',
  4: 'host',
  5: 'pathname',
  6: 'search',
  8: 'hash'
  // 7: 'qstr', 9: 'hashQstr', // more
};

function arrToObjWithHash(arr, hash) {
  return arr.reduce(function (memo, val, idx) {
    var hashKey = hash[idx];
    if (hashKey) memo[hashKey] = val || '';
    return memo;
  }, {});
}
function parseUri(uri) {
  var parts = uri.match(uriRegx);
  if (!parts.length) throw new Error("Uri is not valid: ".concat(uri));
  var ret = arrToObjWithHash(parts, uriRegxHash);
  var _ref = ret.host ? ret.host.split(':') : [],
    _ref2 = _slicedToArray(_ref, 2),
    _ref2$ = _ref2[0],
    hostname = _ref2$ === void 0 ? '' : _ref2$,
    _ref2$2 = _ref2[1],
    port = _ref2$2 === void 0 ? '' : _ref2$2;
  var origin = "".concat(ret.protocol, "//").concat(hostname).concat(port ? ":".concat(port) : '');
  var search = ret.search.substring(1);
  var value = search.replace(/&/g, '","').replace(/=/g, '":"');
  var params = !value ? {} : JSON.parse("{\"".concat(value, "\"}"), function (key, value) {
    return key === '' ? value : decodeURIComponent(value);
  });
  return _objectSpread(_objectSpread({}, ret), {}, {
    hostname: hostname,
    port: port,
    href: uri,
    origin: origin,
    params: params
  });
}
export default parseUri;