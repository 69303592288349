import css from './index.module.scss';
import { useGlobal } from '@/utils/useGlobal';
import EFDownloadAppPCSvg from '@/components/UI/svg/Icons/EFDownloadAppPCSvg';
import EFAppQRCode from '@/components/UI/svg/Icons/EFAppQRCode';
import { URL } from '@/constants';

export default function DownloadSection({ clickHere, iosAppVersionInfo }: any) {
    const { useLang, Image, Link, useRouter, useEffect, useState } = useGlobal();
    const { locale } = useRouter();
    const { t } = useLang('app');
    const { t: s } = useLang('supportdownloadproduct');
    const { APP_ANDROID, APP_IOS, APK_OVERSEAS, APK_CN } = URL;
    const androidAppVersionInfo = { appVersion: '2.6.2', minOSVersion: '5.0' };
    const [appStoreSrc, setAppStoreSrc] = useState('/img/download/40a660992d8c413ae5e0fad6591d2247.png');
    const [googlePaySrc, setGooglePaySrc] = useState('/img/download/323533c8fa2c9bff498db36c51c4aef4.png');
    const [bottomBg, setBottomBg] = useState('/img/appDownload/pc/05-bg.png');

    const getAppDataLayer = (type: string) => ({
        event: 'uaEvent',
        event_name: 'ecoflow_app',
        button_name: type,
    });

    useEffect(() => {
        if (['us', 'au', 'eu', 'ca', 'uk', 'za', 'cis', 'br'].includes(locale)) {
            setAppStoreSrc('/img/download/40a660992d8c413ae5e0fad6591d2247.png');
            setGooglePaySrc('/img/download/323533c8fa2c9bff498db36c51c4aef4.png');
            setBottomBg(['us'].includes(locale) ? '/img/appDownload/pc/05-bg-diff.jpg' : '/img/appDownload/pc/05-bg.png');
        } else {
            setAppStoreSrc(`/img/appDownload/pc/apple-store-${locale}.png`);
            setGooglePaySrc(`/img/appDownload/pc/google-play-${locale}.png`);
            setBottomBg(`/img/appDownload/pc/05-bg-${locale}.png`);
        }
    }, []);

    return (
        <div className={css.downloadSection}>
            <div className="download-section">
                <div className="title">{t('downloadSection.title')}</div>
                <div className="download-box">
                    <Image className="fullImgs radius-img" layout="fill" src={bottomBg} />
                    <div className="logo-box">
                        <div className="logo">
                            <Image className="fullImgs" layout="fill" src="/img/appDownload/pc/05-logo.png" />
                        </div>
                        <div className="logo-text">{t('downloadSection.ecoflow-app')}</div>
                    </div>
                    <div className="logo-desc">{t('downloadSection.app-desc')}</div>
                    <div className="download-list">
                        <div className="app-store">
                            <div className="app-store__logo">
                                <Link href={APP_IOS} ariaLabel={t('support.download.app_store')} dataLayer={getAppDataLayer('App Store')}>
                                    <Image src={appStoreSrc} layout="fill" className="fullImgs" />
                                </Link>
                            </div>
                            <div className="text">{`${t('downloadSection.apple-store')}${iosAppVersionInfo.minOSVersion || '11.0'} ${t(
                                'downloadSection.or-above',
                            )}`}</div>
                        </div>
                        <div className="google-play">
                            <div className="google-play__logo">
                                <Link href={APP_ANDROID} ariaLabel={t('support.download.google_play')} dataLayer={getAppDataLayer('Google Play')}>
                                    <Image src={googlePaySrc} layout="fill" className="fullImgs" />
                                </Link>
                            </div>
                            <div className="text">{`${t('downloadSection.google-play')}${androidAppVersionInfo.minOSVersion} ${t(
                                'downloadSection.or-above',
                            )}`}</div>
                        </div>
                    </div>
                    <Link
                        href={locale === 'cn' ? APK_CN : APK_OVERSEAS}
                        ariaLabel={s('site_support_download_apk')}
                        selfWin
                        dataLayer={getAppDataLayer('Download the EcoFlow App apk file')}
                    >
                        <div className="download-link">
                            <EFDownloadAppPCSvg width="14px" height="14px" className="download-svg" />
                            <div className="link-text">{t('downloadSection.link-text')}</div>
                        </div>
                    </Link>
                    <div className="qr-code">
                        <EFAppQRCode className="qr-code__box" />
                        <div className="code-text">{t('downloadSection.qr-code')}</div>
                    </div>
                </div>
                <div className="click-here">
                    {t.rich('downloadSection.click-here', {
                        line1: (text: any) => (
                            <span className="color-ms1" onClick={clickHere}>
                                {text}
                            </span>
                        ),
                    })}
                </div>
            </div>
        </div>
    );
}
