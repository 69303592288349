import { useRef } from 'react';
import { createPortal } from 'react-dom';
import css from './index.module.scss';
import IoMdCloseCircle from '@/components/UI/svg/Icons/IoMdCloseCircle';
import Aliplayer from '@/components/UI/Aliplayer';
import { useGlobal } from '@/utils/useGlobal';

export enum PlayerType {
    YouTube = 'YouTube',
    Alibaba = 'Alibaba',
    Bilibili = 'Bilibili',
    Facebook = 'Facebook',
}

type params = {
    visible: boolean;
    source: string | object;
    playerType: PlayerType | string;
    onClose: () => void;
    modalConfig?: {
        width: number;
        height: number;
    };
};

export default function EFVideoModal({ visible, source, playerType, onClose, modalConfig }: params) {
    const { isMobile, useState, useEffect } = useGlobal();
    const [winH, setWinH] = useState(1080);
    const [winW, setWinW] = useState(1920);
    const defaultConfig = {
        width: isMobile ? 300 : 840,
        height: isMobile ? 220 : 472,
    };
    const { width, height } = { ...defaultConfig, modalConfig };
    const videoRef = useRef(null);
    const bRef = useRef(null);
    const [isClient, setIsClient] = useState<boolean>(false);

    // 隐藏滚动条
    useEffect(() => {
        setIsClient(true);
        document.documentElement.style.overflow = visible ? 'hidden' : '';
        return () => {
            document.documentElement.style.overflow = '';
        };
    }, [visible]);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            setWinH(window.innerHeight);
            setWinW(window.innerWidth);
        }
    }, []);

    const renderPlayer = (type: PlayerType | string) => {
        switch (type) {
            case PlayerType.YouTube:
                return (
                    <iframe
                        ref={videoRef}
                        className="youtube-player"
                        onLoad={(elem) => {
                            if (videoRef.current) {
                                videoRef.current.style.display = 'block';
                            }
                        }}
                        width={width}
                        height={height}
                        src={source}
                        frameBorder="0"
                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; autoplay"
                    />
                );
            case PlayerType.Alibaba:
                return (
                    /**
                     * 多清晰度播放，source传入一个JSON
                     *
                     * "OD": "<原画URL>"
                     * "FD": "<流畅URL>"
                     * "LD"："<标清URL>"
                     * "SD": "<高清URL>" 480P
                     * "HD": "<超清URL>" 720P
                     * "2K": "<2K URL>" 1080P
                     * "4K": "<4K URL>" UHD
                     *
                     * 默认播放率按 source 排序
                     */
                    //  source: {
                    //     "HD":"http://xxxxx/player/hdexample.mp4",
                    //     "SD":"http://xxxxx/player/sdexample.mp4"
                    // }
                    <Aliplayer className="video-ali" source={JSON.stringify(source)} width={`${width}px`} height={`${height}px`} autoplay={true} />
                );
            case PlayerType.Bilibili:
                return (
                    <iframe
                        ref={bRef}
                        onLoad={() => {
                            if (bRef.current) {
                                (bRef.current as HTMLDivElement).style.display = 'block';
                            }
                        }}
                        width={width}
                        height={height}
                        src={source}
                        scrolling="no"
                        border="0"
                        frameBorder="no"
                        framespacing="0"
                        allowFullScreen="true"
                    ></iframe>
                );
            default:
                return <></>;
        }
    };

    return (
        <>
            {isClient &&
                createPortal(
                    <div className={css.EFVideoModal}>
                        {visible && (
                            <div className="ef-video-modal">
                                <IoMdCloseCircle
                                    className="close-btn"
                                    style={{
                                        top: winH / 2 - (isMobile ? 220 : 300) < 0 ? '20px' : '',
                                        right: winH / 2 - (isMobile ? 290 : 500) < 0 ? '20px' : '',
                                    }}
                                    onClick={onClose}
                                />
                                {renderPlayer(playerType)}
                            </div>
                        )}
                    </div>,
                    document.body,
                )}
        </>
    );
}
