import { Select } from 'antd';
import css from './index.module.scss';
import IotComp from './IotComp';
import DirectConnection from './DirectConnection';
import Bluetooth from './Bluetooth';
import { useGlobal } from '@/utils/useGlobal';
import { EFArrowRightDown } from '@/components/UI/svg/appDownload';

const { Option } = Select;

export default function QuickGuide() {
    const { useLang, useState, useRouter } = useGlobal();
    const { t } = useLang('app');
    const { locale } = useRouter();
    const [active, setActive] = useState(0);
    const [activeRight, setActiveRight] = useState(0);
    const isShowEn = ['us', 'au', 'eu', 'ca', 'uk', 'za', 'cis', 'br'].includes(locale);

    const categoryTree = [
        { title: t('quickGuideChild.tree1'), value: '0' },
        { title: t('quickGuideChild.tree2'), value: '1' },
        {
            title: t('quickGuideChild.tree3'),
            value: '2',
            children: [
                {
                    title: t('quickGuideChild.tree3-iot'),
                    value: '3',
                    desc1: t('quickGuideChild.iot-model1'),
                    desc2: t('quickGuideChild.iot-model2'),
                    step: [
                        {
                            title: t('quickGuideChild.iot-step1'),
                            img: isShowEn ? '/img/appDownload/pc/iot/ced92bc6a6b4df2c28b21db137138d77.png' : `/img/appDownload/pc/iot/1-${locale}.png`,
                        },
                        {
                            title: t('quickGuideChild.iot-step2'),
                            img: isShowEn ? '/img/appDownload/pc/iot/0ac625d4525dbee0a1cf8c564594fccc.png' : `/img/appDownload/pc/iot/2-${locale}.png`,
                        },
                        {
                            title: t('quickGuideChild.iot-step3'),
                            img: isShowEn ? '/img/appDownload/pc/iot/b8f9595398c42879e72db67a4c9676ac.png' : `/img/appDownload/pc/iot/3-${locale}.png`,
                        },
                    ],
                    note: t('quickGuideChild.note1'),
                },
                {
                    title: t('quickGuideChild.tree3-direct'),
                    value: '4',
                    desc1: t('quickGuideChild.direct-model1'),
                    desc2: t('quickGuideChild.direct-model2'),
                    step: [
                        {
                            title: t('quickGuideChild.direct-step1'),
                            img: isShowEn ? '/img/appDownload/pc/connection/0ecd27d9b6a923bdbcd38ba268aa089e.png' : `/img/appDownload/pc/connection/1-${locale}.png`,
                        },
                        {
                            title: t('quickGuideChild.direct-step2'),
                            img: isShowEn ? '/img/appDownload/pc/connection/38f105a7df959691d878befbedf56a91.png' : `/img/appDownload/pc/connection/2-${locale}.png`,
                        },
                        {
                            title: t('quickGuideChild.direct-step3'),
                            img: isShowEn ? '/img/appDownload/pc/connection/ced92bc6a6b4df2c28b21db137138d77.png' : `/img/appDownload/pc/connection/3-${locale}.png`,
                        },
                        {
                            title: t('quickGuideChild.direct-step4'),
                            img: isShowEn ? '/img/appDownload/pc/connection/0ac625d4525dbee0a1cf8c564594fccc.png' : `/img/appDownload/pc/connection/4-${locale}.png`,
                        },
                    ],
                    note: t('quickGuideChild.note2'),
                },
                {
                    title: t('quickGuideChild.tree3-bluetooth'),
                    value: '5',
                    desc1: t('quickGuideChild.bluetooth-model1'),
                    desc2: t('quickGuideChild.bluetooth-model2'),
                    step: [
                        {
                            title: t('quickGuideChild.bluetooth-step1'),
                            img: isShowEn ? '/img/appDownload/pc/bluetooth/e83bb1402c3ea1fcada1f5759a0c0043.png' : `/img/appDownload/pc/bluetooth/1-${locale}.png`,
                        },
                        {
                            title: t('quickGuideChild.bluetooth-step2'),
                            img: isShowEn ? '/img/appDownload/pc/bluetooth/00890c54b09e0eefa7ff3fa1e473a819.png' : `/img/appDownload/pc/bluetooth/2-${locale}.png`,
                        },
                        {
                            title: t('quickGuideChild.bluetooth-step3'),
                            img: isShowEn ? '/img/appDownload/pc/bluetooth/0ac625d4525dbee0a1cf8c564594fccc.png' : `/img/appDownload/pc/bluetooth/3-${locale}.png`,
                        },
                    ],
                    note: t('quickGuideChild.note3'),
                },
            ],
        },
    ];

    return (
        <div className={css.quickGuide}>
            <div className="quick-guide">
                <div className="select_box">
                    <Select value={active} suffixIcon={<EFArrowRightDown className="icon" />} onChange={(val) => setActive(Number(val))}>
                        {categoryTree.map((item: any, index: number) => (
                            <Option value={index} key={index}>
                                {item.title}
                            </Option>
                        ))}
                    </Select>
                    {categoryTree[active].children ? (
                        <Select value={activeRight} suffixIcon={<EFArrowRightDown className="icon" />} onChange={(val) => setActiveRight(Number(val))}>
                            {categoryTree[active].children?.map((childItem: any, childIndex: number) => (
                                <Option value={childIndex} key={childIndex}>
                                    {childItem.title}
                                </Option>
                            ))}
                        </Select>
                    ) : (
                        ''
                    )}
                </div>
                <div className="content-box">
                    <div className="child-box">
                        {active === 0 && <IotComp />}
                        {active === 1 && <DirectConnection />}
                        {active === 2 && <Bluetooth data={(categoryTree[active].children as any)[activeRight]} />}
                    </div>
                </div>
            </div>
        </div>
    );
}
