import css from './index.module.scss';
import { useGlobal } from '@/utils/useGlobal';
import Button from '@/components/UI/Button';

export default function ProductNav({ title, className, theme, handleBtnOperate, curActive, callback }: any) {
    const { useState, useEffect, isMobile, useLang, gtm, useRouter } = useGlobal();
    const [isShowNav, setIsShowNav] = useState(false);
    const [idx, setIdx] = useState('overview');
    const [top, setTop] = useState(0);
    const { t } = useLang('app');
    const { locale } = useRouter();

    const tabMap: any = {
        overview: t('overview'),
        quickGuide: t('quickGuide'),
        tutorialVideos: t('tutorialVideos'),
    };
    const tabChange = (name: string) => {
        if (typeof document !== 'undefined') {
            document.querySelectorAll('.ecoflow-frame-totop')[0]?.click();
        }
        gtm.push({
            event: 'uaEvent',
            event_name: 'product_navigation',
            navigation_name: name === 'quickGuide' ? 'FAQ' : name,
            product_name: title,
        });
        setIdx(name);
        callback(name);
    };

    const mTabChange = (name: string) => {
        if (typeof document !== 'undefined') {
            document.querySelectorAll('.ecoflow-frame-totop')[0]?.click();
        }
        gtm.push({
            event: 'uaEvent',
            event_name: 'product_navigation',
            navigation_name: name === 'quickGuide' ? 'FAQ' : name,
            product_name: title,
        });
        setIdx(name);
        callback(name);
        setIsShowNav(false);
    };

    const styleTrans = (theme: string) => ({
        color: theme == 'black' ? '#fff' : '#03060b',
    });

    const handleClickOtherDom = (e: MouseEvent) => {
        const shouleHideEle = document.querySelector('.detail-nav');
        const clickEle = e.target as Element;
        if (!shouleHideEle?.contains(clickEle)) {
            setIsShowNav(false);
        }
    };

    useEffect(() => {
        setIdx(curActive);
    }, [curActive]);

    const navTheme = theme === 'black' ? 'black' : '';

    useEffect(() => {
        setTimeout(() => {
            const header = document.querySelector('.ecoflow-frame-header') as HTMLDivElement;
            const bar = document.querySelector('.announcement-bar') as HTMLElement;
            if (header && bar) {
                const computedHeaderStyle = window.getComputedStyle(header);
                const positionHeaderValue = computedHeaderStyle.getPropertyValue('position');
                const computedBarStyle = window.getComputedStyle(bar);
                const positionBarValue = computedBarStyle.getPropertyValue('position');
                if (positionHeaderValue === 'sticky') {
                    setTop(header?.offsetHeight + (positionBarValue === 'sticky' ? bar?.offsetHeight : 0));
                }
                if (positionHeaderValue === 'relative') {
                    setTop(0 + (positionBarValue === 'sticky' ? bar?.offsetHeight : 0));
                }
            }
        }, 3000);
        document.addEventListener('touchstart', handleClickOtherDom);
        return () => {
            document.removeEventListener('touchstart', handleClickOtherDom);
        };
    }, []);

    return (
        <>
            <section className={`${css.naver} product_naver naver ${className} cover ${navTheme}`} style={{ top }}>
                <div className="container">
                    <div className={`detail-nav ${idx === 'overview' ? 'black' : navTheme}`}>
                        <h1 className="left" style={styleTrans(navTheme)}>
                            {title}
                        </h1>
                        <div className="right">
                            {isMobile ? (
                                <div
                                    className="tab-dropdown"
                                    onClick={() => {
                                        setIsShowNav(!isShowNav);
                                    }}
                                >
                                    <span className="tab-dropdown-name">{tabMap[idx] || idx}</span>
                                    <span className="tab-dropdown-icon"></span>
                                </div>
                            ) : (
                                <ul className="rightLists">
                                    <li onClick={() => tabChange('overview')} className={idx === 'overview' ? `active black` : ''} style={styleTrans(navTheme)}>
                                        <span> {t('overview')}</span>
                                    </li>
                                    <li
                                        onClick={() => tabChange('quickGuide')}
                                        className={idx == 'quickGuide' ? `active ${navTheme}` : ''}
                                        style={styleTrans(navTheme)}
                                    >
                                        <span> {t('quickGuide')}</span>
                                    </li>
                                    {!['cn', 'cis'].includes(locale) && (
                                        <li
                                            onClick={() => tabChange('tutorialVideos')}
                                            className={idx == 'tutorialVideos' || idx == 'tutorialVideos' ? `active ${navTheme}` : ''}
                                            style={styleTrans(navTheme)}
                                        >
                                            <span> {t('tutorialVideos')}</span>
                                        </li>
                                    )}
                                </ul>
                            )}

                            <span className="btn-link" onClick={handleBtnOperate}>
                                <Button type="primary" className="default-btn small buy-btn" aria-label={title}>
                                    {t('down')}
                                </Button>
                            </span>
                            {isShowNav && (
                                <div className="vertical-nav">
                                    <ul>
                                        <li className={idx == 'overview' ? `active ${navTheme}` : ''} onClick={() => mTabChange('overview')}>
                                            {t('overview')}
                                        </li>
                                        <li className={idx == 'quickGuide' ? `active ${navTheme}` : ''} onClick={() => mTabChange('quickGuide')}>
                                            {t('quickGuide')}
                                        </li>
                                        {!['cn', 'cis'].includes(locale) && (
                                            <li className={idx == 'tutorialVideos' ? `active ${navTheme}` : ''} onClick={() => mTabChange('tutorialVideos')}>
                                                {t('tutorialVideos')}
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
