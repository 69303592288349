import { FC } from 'react';
import css from './index.module.scss';
import { useGlobal } from '@/utils/useGlobal';

export const Tips: FC = () => {
    const { useLang } = useGlobal();
    const { t } = useLang('app.upgrade');

    return (
        <div className={css.Tips}>
            <p className="text">{t('tips')}</p>
        </div>
    );
};
