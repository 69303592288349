export default function (props: any) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g id="定稿" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="M端-app4.0详情页" transform="translate(-60.000000, -4598.000000)" fill="#000000" fillRule="nonzero">
                    <g id="编组-13" transform="translate(25.000000, 4350.000000)">
                        <g id="编组" transform="translate(35.000000, 157.000000)">
                            <g id="编组-5" transform="translate(0.000000, 89.000000)">
                                <g id="编组-2" transform="translate(0.000000, 2.000000)">
                                    <rect id="矩形" opacity="0" x="0" y="0" width="14" height="14"></rect>
                                    <path
                                        d="M13.0265625,9.139375 C13.3196359,9.14630087 13.5555741,9.38223911 13.5625,9.6753125 C13.5625,11.4625 12.2740625,12.893125 10.70125,12.893125 L10.70125,12.893125 L3.29875,12.893125 C1.7259375,12.893125 0.4375,11.4625 0.4375,9.6753125 C0.44442587,9.38223911 0.680364113,9.14630087 0.9734375,9.139375 C1.26651089,9.14630087 1.50244913,9.38223911 1.509375,9.6753125 C1.509375,10.854375 2.296875,11.8125 3.29875,11.82125 L3.29875,11.82125 L10.70125,11.82125 C11.703125,11.82125 12.490625,10.854375 12.490625,9.6753125 C12.4975509,9.38223911 12.7334891,9.14630087 13.0265625,9.139375 Z M7.05359375,1.12366716 C7.33747777,1.12366716 7.57253442,1.34419298 7.590625,1.6275 L7.590625,1.6275 L7.590625,7.70875 L8.6996875,6.5996875 C8.9101428,6.41372201 9.22895079,6.42356019 9.42753905,6.62214845 C9.62612731,6.82073671 9.63596549,7.1395447 9.45,7.35 L9.45,7.35 L7.41125,9.38875 C7.18289545,9.57809816 6.85210455,9.57809816 6.62375,9.38875 L6.62375,9.38875 L4.6571875,7.35 C4.47122201,7.1395447 4.48106019,6.82073671 4.67964845,6.62214845 C4.87823671,6.42356019 5.1970447,6.41372201 5.4075,6.5996875 L5.4075,6.5996875 L6.5165625,7.70875 L6.5165625,1.6275 C6.53465308,1.34419298 6.76970973,1.12366716 7.05359375,1.12366716 Z"
                                        id="形状结合"
                                        stroke="#000000"
                                        strokeWidth="0.3"
                                    ></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}
