import { useRef, useMemo, useEffect } from 'react';
import Head from 'next/head';
import Script from 'next/script';

/**
 * 多清晰度播放，source传入一个JSON
 *
 * "OD": "<原画URL>"
 * "FD": "<流畅URL>"
 * "LD"："<标清URL>"
 * "SD": "<高清URL>"
 * "HD": "<超清URL>"
 * "2K": "<2K URL>"
 * "4K": "<4K URL>"
 * "示例": source: '{"HD":"http://xxxxx/player/hdexample.mp4","SD":"http://xxxxx/player/sdexample.mp4"}'
 */

interface AliplayerProps {
    source: string; //视频源地址
    cover?: string; // 自定义封面需要关闭preload & autoplay
    width?: string;
    height?: string;
    autoplay?: boolean;
    isLive?: boolean; //直播
    rePlay?: boolean; // 循环播放
    playsinline?: boolean;
    preload?: boolean; //提前加载
    controlBarVisibility?: 'hover' | 'click' | 'always'; //控制面板的实现
    useH5Prism?: boolean;
    className?: string;
}

const AliVideoPlayer = ({
    source,
    width = '100%',
    height = '100%',
    controlBarVisibility = 'hover',
    isLive = false,
    playsinline = true,
    className,
    ...rst
}: AliplayerProps) => {
    const palyer = useRef(null);
    const id = useMemo(() => `aliplayer-${Math.floor(Math.random() * 1000000)}`, []);

    const config = {
        source,
        id,
        width,
        height,
        controlBarVisibility,
        isLive,
        playsinline,
        ...rst,
    };

    useEffect(() => {
        if ('Aliplayer' in window) {
            palyer.current = new window.Aliplayer(config);
        }
    }, []);

    return (
        <>
            <Head>
                <link rel="stylesheet" type="text/css" href="https://g.alicdn.com/de/prismplayer/2.11.0/skins/default/aliplayer-min.css" />
            </Head>
            {!('Aliplayer' in window) && (
                <Script
                    strategy="lazyOnload"
                    src="https://g.alicdn.com/de/prismplayer/2.11.0/aliplayer-min.js"
                    onLoad={() => {
                        setTimeout(() => {
                            palyer.current = new window.Aliplayer(config);
                        });
                    }}
                />
            )}
            <div className={`prism-player ${className}`} id={id}></div>
        </>
    );
};

export default AliVideoPlayer;
