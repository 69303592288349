import { Input, Menu } from 'antd';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import css from './index.module.scss';
import { useGlobal } from '@/utils/useGlobal';
import EFArrowDown from '@/components/UI/svg/Icons/EFArrowDown';
import { createEFPic } from '@/utils/useEFPic';

const { EFPic } = createEFPic('/media/support-download-index/pc/');
const { SubMenu } = Menu;
export default function SupportLeftMenu({
    categoryTree,
    defaultOpenIds = [],
    defaultSelectId = [],
    fieldNames = { title: 'title', value: 'value', children: 'children', type: 'faqCatagoryType' },
    onSelect,
    onSearch,
    showSearch = true,
}: any) {
    const { useState, useLang, gtm } = useGlobal();
    const rootSubmenuKeys = categoryTree?.map((item: any) => item[fieldNames.value]) || [];
    const [openKeys, setOpenKeys] = useState(defaultOpenIds);
    const [selectKeys, setSelectKeys] = useState(defaultSelectId);
    const { t } = useLang('shared');

    const reverseFindPath = (list: any, conditionFunc: any, path = []) => {
        if (!list) return [];
        for (const data of list) {
            path.push(data.name);
            if (conditionFunc(data)) return path;
            if (data.subs) {
                const findChildren: any = reverseFindPath(data.subs, conditionFunc, path);
                if (findChildren.length) return findChildren;
            }
            path.pop();
        }
        return [];
    };

    const onNavigationGTM = (key: any) => {
        const nameArr = reverseFindPath(categoryTree, (data: any) => data.downloadCatagoryId === key);
        gtm.push({
            event: 'uaEvent',
            event_name:
                nameArr.length === 1
                    ? 'manuals_first_navigation'
                    : nameArr.length === 2
                    ? 'manuals_second_navigation'
                    : nameArr.length === 3
                    ? 'manuals_third_navigation'
                    : '',
            navigation_name: nameArr.join('_'),
        });
    };

    // 将树结构转为扁平数组
    function flatten(data: any): any {
        if (!Array.isArray(data)) {
            return [];
        }
        return data.reduce((arr: any[], curr: any) => {
            const subs = curr[fieldNames.children];
            const res = {
                [fieldNames.value]: curr[fieldNames.value],
                [fieldNames.title]: curr[fieldNames.title],
                pid: curr.pid,
                [fieldNames.type]: curr[fieldNames.type],
                hasChildren: !isEmpty(subs),
            };
            return arr.concat([res], flatten(subs), []);
        }, []);
    }

    // 获取所有子节点id, 返回id数组(仅针对PRODUCT_CATAGORY类型)
    const getAllChildrenId = (arr: any[], pid: string, res: any[] = []) => {
        arr.forEach((item) => {
            if (item.pid === pid && item[fieldNames.type] === 'PRODUCT_CATAGORY') {
                res.push(item[fieldNames.value]);
                if (item.hasChildren) {
                    getAllChildrenId(arr, item[fieldNames.value], res);
                }
            }
        });
        return res;
    };
    // 目录打开
    const onOpenChange = (keys: string[]) => {
        const latestOpenKey = keys?.find((key) => openKeys.indexOf(key) === -1) as string;
        // 打开菜单 更新右侧下载列表
        if (keys?.length > openKeys.length) {
            const categoryFlatTree = flatten(categoryTree);
            const categoryids = [latestOpenKey, ...getAllChildrenId(categoryFlatTree, latestOpenKey)];
            /* callback */
            onSelect(categoryids.join(), 'PRODUCT_CATAGORY');
            setSelectKeys([]);
        }

        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };
    const generatorCategoryTree = (dataList: any) => {
        return dataList?.map((dataItem: any) => {
            if (isEmpty(dataItem[fieldNames.children])) {
                return <Menu.Item key={dataItem[fieldNames.value]}>{dataItem[fieldNames.title]}</Menu.Item>;
            }
            return (
                <SubMenu key={dataItem[fieldNames.value]} title={dataItem[fieldNames.title]} onTitleClick={({ key }) => onNavigationGTM(key)}>
                    {generatorCategoryTree(dataItem[fieldNames.children])}
                </SubMenu>
            );
        });
    };
    // 初始化目录树

    const findCurrentCategory = (key: string) => {
        let res: any;
        const findCategory = (list: any[]) => {
            for (let i = 0; i < list.length; i++) {
                const element = list[i];
                if (element.downloadCatagoryId === key) {
                    res = element;
                } else if (element.subs && element?.subs?.length) {
                    findCategory(element.subs);
                }
            }
        };
        findCategory(categoryTree);
        return res;
    };
    // 选择目录
    const onSelectLocal = ({ key }: any) => {
        setSelectKeys([key]);
        /* callback */
        const curr = findCurrentCategory(key);
        onSelect(key, curr?.downloadCatagoryType || 'SPU');
    };

    // 搜索框
    const onSearchLocal = (e: any) => {
        const value = e.target.value;
        if (e.keyCode !== 13) return;
        gtm.push({
            event: 'uaEvent',
            event_name: 'search',
            search_term: value,
            button_name: '',
            type: 'Enter',
        });
        /* callback */
        onSearch(value);
    };

    const initCategoryTree = () => generatorCategoryTree(categoryTree);

    return (
        <div className={css.supportLeftMenu}>
            {showSearch && (
                <Input
                    className="search-input"
                    onKeyDown={debounce(onSearchLocal, 300)}
                    prefix={<EFPic name={['search-icon.webp', 'search-icon.png']} alt={t('support.download.search_icon')} className="searchIcon" />}
                    placeholder={t('support.faq.site_support_faq_search')}
                    aria-label={t('support.faq.site_support_faq_search')}
                />
            )}
            <Menu
                className="ef-download-menu-custom z1"
                mode="inline"
                inlineIndent={15}
                openKeys={openKeys}
                selectedKeys={selectKeys}
                onOpenChange={onOpenChange}
                onSelect={onSelectLocal}
                onClick={({ key }) => onNavigationGTM(key)}
                expandIcon={(props) => (
                    <div className={`icon ${props.isOpen ? 'open' : ''}`}>
                        <EFArrowDown style={{ color: '#03060b', fontSize: '14px' }} />
                    </div>
                )}
            >
                {initCategoryTree()}
            </Menu>
        </div>
    );
}
