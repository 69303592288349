import { Part3 } from './components/Part3';
import { Part4 } from './components/Part4';
import { Tips } from './components/Tips';

export default function Index() {
    return (
        <div style={{ background: '#fff' }}>
            <Part3 />
            <Part4 />
        </div>
    );
}

export function PartTips() {
    return <Tips />;
}
