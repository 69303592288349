import { FC, useState } from 'react';
import EFPicture from 'elem/compCommon/EFPicture';
import css from './index.module.scss';
import { useGlobal } from '@/utils/useGlobal';
import EFVideo from '@/components/UI/EFVideo';

const Icon1 = ({ color = 'white' }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
        <path
            d="M15.6535 6.13286C15.8548 6.28024 15.9736 6.5139 15.9736 6.76234V16.4347C15.9736 16.8665 15.6213 17.2166 15.1869 17.2166H1.81318C1.37871 17.2166 1.0265 16.8665 1.0265 16.4347V6.76234C1.0265 6.5139 1.14529 6.28024 1.34652 6.13286L8.03336 1.23568C8.31087 1.03244 8.68918 1.03244 8.96669 1.23568L15.6535 6.13286ZM15.6535 6.13286L15.8963 6.42439"
            stroke={color}
            strokeWidth="1.1"
            strokeLinecap="round"
        />
        <path
            d="M6.4834 12.6791C6.4834 12.4006 6.70912 12.1749 6.98757 12.1749H10.0126C10.291 12.1749 10.5167 12.4006 10.5167 12.6791V17.2166H6.4834V12.6791Z"
            stroke={color}
            strokeWidth="1.1"
        />
    </svg>
);

const Icon2 = ({ color = 'black' }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="22" viewBox="0 0 18 22" fill="none">
        <path
            d="M12.2571 3.71265C11.2221 3.22969 10.0676 2.95996 8.85011 2.95996C4.39502 2.95996 0.783447 6.57154 0.783447 11.0266C0.783447 12.2781 1.06843 13.463 1.57705 14.52M4.9614 18.0958C6.11465 18.7316 7.44014 19.0933 8.85011 19.0933C13.3052 19.0933 16.9168 15.4817 16.9168 11.0266C16.9168 9.61922 16.5563 8.29598 15.9227 7.14419"
            stroke={color}
            strokeWidth="1.1"
            strokeLinecap="round"
        />
        <path d="M6.43005 13.4465L8.85005 7.7998L11.2701 13.4465" stroke={color} strokeWidth="1.1" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7.23682 11.8331H10.4635" stroke={color} strokeWidth="1.1" strokeLinecap="round" />
        <path d="M10.1995 1.46326L12.2572 3.71252" stroke={color} strokeWidth="1.1" strokeLinecap="round" />
        <path d="M4.96143 18.0957L7.23681 20.6383" stroke={color} strokeWidth="1.1" strokeLinecap="round" />
    </svg>
);

export const Part4: FC = () => {
    const { useLang } = useGlobal();
    const { t } = useLang('app.upgrade');
    const [active, setActive] = useState('left');

    const onSwitch = (value: string) => setActive(value);

    return (
        <>
            <div className={css.Part4}>
                <p className="title">{t('part4_title')}</p>
                <p className="desc">{t('part4_desc')}</p>
                <div className="video">
                    <EFVideo
                        sources={['https://ecoflow-service-us-cdn.ecoflow.com/upload/video/1712826872998/app_5_pc_us.mp4']}
                        aria-label={t('part4_title')}
                    />
                </div>
                <div className="video-h5">
                    <EFVideo
                        sources={['https://ecoflow-service-us-cdn.ecoflow.com/upload/video/1712483084532/app_4_h5_us.mp4']}
                        aria-label={t('part4_title')}
                    />
                </div>
            </div>
            <div className={css.Part5}>
                <div className="text-wrapper">
                    <p className="title">{t('part5_title')}</p>
                    <p className="desc">{t('part5_desc')}</p>
                </div>
                <EFVideo
                    className="video-pc"
                    sources={['https://ecoflow-service-us-cdn.ecoflow.com/upload/video/1712817366526/app_5_pc_us.mp4']}
                    aria-label={t('part5_title')}
                />
                <EFVideo
                    className="video-h5"
                    sources={['https://ecoflow-service-us-cdn.ecoflow.com/upload/video/1712817384322/app_5_h5_us.mp4']}
                    aria-label={t('part5_title')}
                />
            </div>
            <div className={css.Part6}>
                <div className="text-wrapper">
                    <p className="title">{active === 'left' ? t('part6_title') : t('part6_title2')}</p>
                    <p className="desc">{active === 'left' ? t('part6_desc') : t('part6_desc2')}</p>
                </div>
                <div className="video">
                    <EFVideo
                        className={`vide-1 ${active === 'left' ? 'show' : ''}`}
                        sources={['https://ecoflow-service-us-cdn.ecoflow.com/upload/video/1712817696164/app_6_1_pc_us.mp4']}
                        aria-label={t('part6_title')}
                    />
                    <EFVideo
                        className={`vide-2 ${active === 'right' ? 'show' : ''}`}
                        sources={['https://ecoflow-service-us-cdn.ecoflow.com/upload/video/1712817683889/app_6_2_pc_us.mp4']}
                        aria-label={t('part6_title2')}
                    />
                </div>
                <div className="video-h5">
                    <EFVideo
                        className={`vide-1 ${active === 'left' ? 'show' : ''}`}
                        sources={['https://ecoflow-service-us-cdn.ecoflow.com/upload/video/1712817654222/app_6_1_h5_us.mp4']}
                        aria-label={t('part6_title')}
                    />
                    <EFVideo
                        className={`vide-2 ${active === 'right' ? 'show' : ''}`}
                        sources={['https://ecoflow-service-us-cdn.ecoflow.com/upload/video/1712817667977/app_6_2_h5_us.mp4']}
                        aria-label={t('part6_title2')}
                    />
                </div>
                <div className="switch">
                    <div className={`left ${active === 'left' ? 'active' : ''}`} onClick={() => onSwitch('left')}>
                        <Icon1 color={active === 'left' ? 'white' : 'black'} />
                        <span className="text">{t('part6_btn1')}</span>
                    </div>
                    <div className={`right ${active === 'right' ? 'active' : ''}`} onClick={() => onSwitch('right')}>
                        <Icon2 color={active === 'right' ? 'white' : 'black'} />
                        <span className="text">{t('part6_btn2')}</span>
                    </div>
                </div>
            </div>
            <div className={css.Part7}>
                <p className="title">{t('part7_title')}</p>
                <p className="desc">{t('part7_desc')}</p>
                <div className="img">
                    <EFPicture
                        src="https://ecoflow-service-us-cdn.ecoflow.com/upload/image/1712474995024/app_7_pc_us.png"
                        srcPad="https://ecoflow-service-us-cdn.ecoflow.com/upload/image/1712474995024/app_7_pc_us.png"
                        srcMobile="https://ecoflow-service-us-cdn.ecoflow.com/upload/image/1712474995024/app_7_pc_us.png"
                        alt={t('part3_title')}
                    />
                </div>
            </div>
            <div className={css.Part8}>
                <p className="title">{t('part8_title')}</p>
                <p className="desc">{t('part8_desc')}</p>
                <div className="img">
                    <EFPicture
                        src="https://ecoflow-service-us-cdn.ecoflow.com/upload/image/1712475010244/app_8_pc_us.jpg"
                        srcPad="https://ecoflow-service-us-cdn.ecoflow.com/upload/image/1712475010244/app_8_pc_us.jpg"
                        srcMobile="https://ecoflow-service-us-cdn.ecoflow.com/upload/image/1712483722072/app_8_h5_us.jpg"
                        alt={t('part3_title')}
                    />
                </div>
            </div>
        </>
    );
};
