import css from './index.module.scss';
import { useGlobal } from '@/utils/useGlobal';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectFade } from 'swiper';
import { TimelineMax, TweenMax } from 'gsap';

SwiperCore.use([EffectFade]);

export default function SecondSeriesInApp() {
    const { useLang, Image, useEffect, useRouter } = useGlobal();
    const { t } = useLang('app');
    const { locale } = useRouter();
    const swiperList = [
        {
            img: ['us', 'au', 'eu', 'ca', 'uk', 'za', 'cis', 'br'].includes(locale) ? '/img/appDownload/h5/04-1.png' : `/img/appDownload/h5/04-1-${locale}.png`,
            title: t('secondSeriesInApp.phone-txt1'),
            desc: t('secondSeriesInApp.phone-desc1'),
        },
        {
            img: ['us', 'au', 'eu', 'ca', 'uk', 'za', 'cis', 'br'].includes(locale) ? '/img/appDownload/h5/04-2.png' : `/img/appDownload/h5/04-2-${locale}.png`,
            title: t('secondSeriesInApp.phone-txt2'),
            desc: t('secondSeriesInApp.phone-desc3'),
        },
        {
            img: ['us', 'au', 'eu', 'ca', 'uk', 'za', 'cis', 'br'].includes(locale) ? '/img/appDownload/h5/04-3.png' : `/img/appDownload/h5/04-3-${locale}.png`,
            title: t('secondSeriesInApp.phone-txt3'),
            desc: t('secondSeriesInApp.phone-desc2'),
        },
    ];

    let controller: { addScene: (arg0: any) => void };

    useEffect(() => {
        controller = new ScrollMagic.Controller();
    }, []);

    useEffect(() => {
        const scene = new ScrollMagic.Scene({
            triggerElement: '.secondSeriesInApp', //触发元素
            offset: 0, //从开始点滚动多少px触发,可为负数
            triggerHook: 0.15, //触发元素开始进入视口时触发:1 进入,0 离开, 0.5 中间,
            duration: '80%', //和滚动条绑定到一起,场景持续滚动距离为 800px
        });
        const timeline = new TimelineMax();
        const tween1 = TweenMax.to('.secondSeriesInApp .img-box', 0.6, {
            width: '3.49rem',
            height: '2.89rem',
        });
        const tween2 = TweenMax.to('.secondSeriesInApp .move-box', 0.6, { top: '-2.886rem' });
        // const tween3 = TweenMax.to('.secondSeriesInApp .img-box', 0.6, { width: '3.49rem', height: '2.89rem' })
        timeline.add(tween1).add(tween2);
        scene.setTween(timeline);
        controller.addScene(scene);
    }, []);

    return (
        <div className={`${css.secondSeriesInApp} secondSeriesInApp`}>
            <div className="second-series">
                <div className="title">{t('secondSeriesInApp.title')}</div>
                <div className="show-box">
                    <div className="img-box">
                        <Image className="fullImgs" layout="fill" src="/img/appDownload/h5/shp.png" />
                    </div>
                </div>

                <div className="move-box">
                    <div className="swiper-box">
                        <Swiper className="swiper-list" pagination={{ clickable: true }} slidesPerView={'auto'} initialSlide={0} effect="fade" loop>
                            {swiperList.map((item, index) => (
                                <SwiperSlide key={index}>
                                    <div className={`swiper-item item-${index + 1}`}>
                                        <div className="title">{item.title}</div>
                                        <div className="desc">{item.desc}</div>
                                        <div className="swiper-img">
                                            <Image src={item.img} className="fullImgs" layout="fill" />
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </div>
        </div>
    );
}
