import { w_tablet, w_desktop, w_desktopMax } from 'elem/utils/devices';
import css from './index.module.scss';

export type PictureType = Omit<HTMLImageElement, 'alt'> & {
    src: string;
    srcDesktop?: string | undefined;
    srcPad?: string | undefined;
    srcMobile?: string | undefined;
    alt?: string;
    'aria-label'?: string;
    className?: string;
};

export default function EFPicture(props: PictureType) {
    const { src, srcMax = '', srcDesktop = '', srcPad = '', srcMobile = '', alt, className, ...rest } = props;
    return (
        <div className={`${css.efPicture} ef-picture${className ? ` ${className}` : ''}`}>
            <picture className="picture">
                {srcMobile && <source media={`(max-width: ${w_tablet - 1}px)`} srcSet={srcMobile} />}
                {srcPad && <source media={`(max-width: ${w_desktop}px)`} srcSet={srcPad} />}
                {srcDesktop && <source media={`(max-width: ${w_desktopMax - 1}px)`} srcSet={srcDesktop} />}
                {srcMax && <source media={`(min-width: 1921px)`} srcSet={srcMax} />}
                <img className="picture-img" src={src} alt={alt || 'EF-Image'} aria-label={alt || 'EF-Image'} {...rest} />
            </picture>
        </div>
    );
}
